import {
    UserDataActionTypes,
    UserDataState,
    USER_DATA
} from './types';
import { isArray } from 'util';

export const initialState: UserDataState = {
    pending: false,
    userData: { id: '', uniqueid: '' },
    userDataResponse: {
        accessEnabled: '',
        email: '', emailVerified: '', firstName: '', id: '', lastName: '', uniqueId: '', cards: null,
        clinics: [],
        doctors: [], status: { error: false, msg: '' }, permissions: [], userAccess: "0",
        pms: false, stripedata: { customerId: '', accountID: '' },
        role: null
    },
    userLogoUpload: { type: '', uphoto: '', uniqueId: '', userType: '' },
    userLogoUploadResponse: { logo: { name: '', type: '' }, status: { error: false, msg: '' }, userType: '', uniqueId: '' },
    userNameChange: { name: '', type: '', uniqueId: '', userType: '' },
    userNameChangeResponse: { name: '', status: { error: false, msg: '' }, uniqueId: '', userType: '' },
    error: null
};

export const userDataReducer = (state = initialState, action: UserDataActionTypes) => {
    const NewState = { ...state }
    switch (action.type) {
        case USER_DATA.LOAD_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error
            }
        case USER_DATA.LOAD_PENDING:
            return {
                ...state,
                pending: action.payload.donotAffect ? false : true,
                userData: action.payload
            }
        case USER_DATA.LOAD_SUCCESS:
            return {
                ...state,
                pending: false,
                userDataResponse: {
                    ...action.userData,
                }
            }
        case USER_DATA.CHANGE_IMAGE_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error
            }
        case USER_DATA.CHANGE_IMAGE_PENDING:
            return {
                ...state,
                pending: false,
                userLogoUpload: action.payload
            }
        case USER_DATA.CHANGE_IMAGE_SUCCESS:
            return {
                ...state,
                pending: false,
                userLogoUploadResponse: action.userLogoUpload,
                userDataResponse: {
                    ...state.userDataResponse,
                    clinics: state.userDataResponse.clinics.map((value) => {
                        if (action.userLogoUpload.userType === 'clinic') {
                            if (action.userLogoUpload.uniqueId === value.uniqueId) {
                                value.logo = action.userLogoUpload.logo;
                            }
                        }
                        return value;
                    }),
                    doctors: state.userDataResponse.doctors.map((value) => {
                        if (action.userLogoUpload.userType === 'doctor') {
                            if (action.userLogoUpload.uniqueId === value.uniqueId) {
                                value.photo = action.userLogoUpload.logo;
                            }
                        }
                        return value;
                    })
                }
            }
        case USER_DATA.CHANGE_NAME_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error
            }
        case USER_DATA.CHANGE_NAME_PENDING:
            return {
                ...state,
                pending: false,
                userNameChange: action.payload
            }
        case USER_DATA.CHANGE_NAME_SUCCESS:
            return {
                ...state,
                pending: false,
                userNameChangeResponse: action.userNameChange,
                userDataResponse: {
                    ...state.userDataResponse,
                    clinics: state.userDataResponse.clinics.map((value) => {
                        if (action.userNameChange.userType === 'clinic') {
                            if (action.userNameChange.uniqueId === value.uniqueId) {
                                value.name = action.userNameChange.name;
                            }
                        }
                        return value;
                    }),
                    doctors: state.userDataResponse.doctors.map((value) => {
                        if (action.userNameChange.userType === 'doctor') {
                            if (action.userNameChange.uniqueId === value.uniqueId) {
                                value.name = action.userNameChange.name;
                            }
                        }
                        return value;
                    })
                }
            }
        case USER_DATA.LOCATION_CHANGE:
            return {
                ...state,
                userDataResponse: {
                    ...state.userDataResponse,
                    clinics: state.userDataResponse.clinics.map((value) => {
                        if (action.location.userType === 'clinic') {
                            if (action.location.uniqueId === value.uniqueId) {
                                value.address = action.location.location;
                            }
                        }
                        return value;
                    })
                }
            }
        case USER_DATA.SPECIALITY_CHANGE:
            if (action.payload.requestData.type === "remove") {
                return {
                    ...state,
                    userDataResponse: {
                        ...state.userDataResponse,
                        doctors: state.userDataResponse.doctors.map((value) => {
                            if (action.payload.requestData.uniqueId === value.uniqueId) {
                                value.specialities = value.specialities.filter((specialityValue: any) => {
                                    return specialityValue.id !== action.payload.requestData.specialityId
                                })
                            }
                            return value;
                        })
                    }
                }
            } else if (action.payload.requestData.type === "") {
                return {
                    ...state,
                    userDataResponse: {
                        ...state.userDataResponse,
                        doctors: state.userDataResponse.doctors.map((value) => {
                            if (action.payload.requestData.uniqueId === value.uniqueId) {
                                if (isArray(value.specialities)) {
                                    value.specialities.push(action.payload.speciality[0]);
                                } else {
                                    value.specialities = [action.payload.speciality[0]]
                                }
                            }
                            return value;
                        })
                    }
                }
            }
            return NewState;
        case USER_DATA.EMAIL_VERIFICATION_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error
            }
        case USER_DATA.EMAIL_VERIFICATION_PENDING:
            return {
                ...state,
                pending: true,
                emailVerificationRequest: action.payload
            }
        case USER_DATA.EMAIL_VERIFICATION_SUCCESS:
            return {
                ...state,
                pending: false,
                emailVerificationResponse: action.emailVerification
            }
        case "NAME_CHANGE_USER":
            return {
                ...state,
                userDataResponse: {
                    ...state.userDataResponse,
                    firstName: action.firstName,
                    lastName: action.lastName
                }
            }
        case "EMAIL_CHANGE_USER":
            return {
                ...state,
                userDataResponse: {
                    ...state.userDataResponse,
                    email: action.email,
                    emailVerified: null
                }
            }
        default:
            return NewState;

    }
};