export enum USER_DATA {
    LOAD_PENDING = 'LOAD_PENDING_USER_DATA',
    LOAD_ERROR = 'LOAD_FAILED_USER_DATA',
    LOAD_SUCCESS = 'LOAD_SUCCESS_USER_DATA',
    CHANGE_IMAGE_PENDING = 'LOAD_CHANGE_IMAGE_PENDING',
    CHANGE_IMAGE_ERROR = 'LOAD_CHANGE_IMAGE_ERROR',
    CHANGE_IMAGE_SUCCESS = 'LOAD_CHANGE_IMAGE_SUCCESS',
    CHANGE_NAME_PENDING = 'LOAD_CHANGE_NAME_PENDING',
    CHANGE_NAME_SUCCESS = 'LOAD_CHANGE_NAME_SUCCESS',
    CHANGE_NAME_ERROR = 'LOAD_CHANGE_NAME_ERROR',
    LOCATION_CHANGE = 'LOCATION_CHANGE',
    SPECIALITY_CHANGE = 'SPECIALITY_CHANGE',
    EMAIL_VERIFICATION_ERROR = 'USER_DATA_EMAIL_VERIFICATION_ERROR',
    EMAIL_VERIFICATION_PENDING = 'USER_DATA_EMAIL_VERIFICATION_PENDING',
    EMAIL_VERIFICATION_SUCCESS = 'USER_DATA_EMAIL_VERIFICATION_SUCCESS'
};

export interface UserDataRequest {
    id?: string;
    uniqueid?: string;
    donotAffect?: boolean;
}
export interface Status {
    error: boolean;
    msg: string;
}
export interface UserDataResponse {
    id: string;
    uniqueId: string;
    firstName: string;
    lastName: string;
    email: string;
    emailVerified: any;
    clinics: any[];
    doctors: any[];
    permissions: any[];
    userAccess: string;
    status: Status;
    donotAffect?: boolean;
    pms: boolean;
    cards: any;
    stripedata: any;
    accessEnabled: any;
    role: any;
}
export interface UserLogoUploadRequest {
    type: string;
    uphoto: any;
    uniqueId: string;
    userType: string;
}
export interface UserLogoUploadResponse {
    logo: {
        name: string;
        type: string;
    },
    userType: string;
    uniqueId: string;
    status: Status
}
export interface UserNameChangeRequest {
    type: string;
    uniqueId: string;
    userType: string;
    name: string;
}
export interface UserNameChangeResponse {
    name: string;
    uniqueId: string;
    userType: string;
    status: Status;
}
export interface EmailVerificationRequest {
    modelType: string;
    modelUniqueid: string;
    email: string;
}
export interface EmailVerificationResponse {
    status: Status;
}
export interface UserDataState {
    pending: boolean;
    userData: UserDataRequest;
    userDataResponse: UserDataResponse;
    userLogoUpload: UserLogoUploadRequest;
    userLogoUploadResponse: UserLogoUploadResponse;
    userNameChange: UserNameChangeRequest;
    userNameChangeResponse: UserNameChangeResponse;
    emailVerificationRequest?: EmailVerificationRequest;
    emailVerificationResponse?: EmailVerificationResponse;
    error: any;
}
export interface LocationChange {
    uniqueId: string;
    userType: string;
    location: any;
}
export interface FetchUserDataPendingAction {
    type: USER_DATA.LOAD_PENDING;
    payload: UserDataRequest
}

export interface FetchUserDataErrorAction {
    type: USER_DATA.LOAD_ERROR;
    error: any;
}
export interface FetchUserDataSuccessAction {
    type: USER_DATA.LOAD_SUCCESS;
    userData: UserDataResponse
}

export interface UploadUserLogoPendingAction {
    type: USER_DATA.CHANGE_IMAGE_PENDING,
    payload: UserLogoUploadRequest
}

export interface UploadUserLogoSuccessAction {
    type: USER_DATA.CHANGE_IMAGE_SUCCESS,
    userLogoUpload: UserLogoUploadResponse
}

export interface UploadUserLogoErrorAction {
    type: USER_DATA.CHANGE_IMAGE_ERROR,
    error: any;
}

export interface UserNameChangingPendingAction {
    type: USER_DATA.CHANGE_NAME_PENDING;
    payload: UserNameChangeRequest
}

export interface UserNameChangingErrorAction {
    type: USER_DATA.CHANGE_NAME_ERROR;
    error: any;
}
export interface UserNameChangingSuccessAction {
    type: USER_DATA.CHANGE_NAME_SUCCESS;
    userNameChange: UserNameChangeResponse;
}

export interface LocationChangeAction {
    type: USER_DATA.LOCATION_CHANGE,
    location: LocationChange
}

export interface SpecialityChangeAction {
    type: USER_DATA.SPECIALITY_CHANGE,
    payload: any
}
export interface EmailVerificationPendingAction {
    type: USER_DATA.EMAIL_VERIFICATION_PENDING;
    payload: EmailVerificationRequest;
}
export interface EmailVerificationSuccessAction {
    type: USER_DATA.EMAIL_VERIFICATION_SUCCESS;
    emailVerification: EmailVerificationResponse;
}
export interface EmailVerificationErrorAction {
    type: USER_DATA.EMAIL_VERIFICATION_ERROR;
    error: any;
}
export interface NameChangeAction {
    type: "NAME_CHANGE_USER";
    firstName: string;
    lastName: string;
}
export interface EmailChangeAction {
    type: "EMAIL_CHANGE_USER";
    email: string;
}
export type UserDataActionTypes = FetchUserDataErrorAction | FetchUserDataPendingAction | FetchUserDataSuccessAction |
    UploadUserLogoPendingAction | UploadUserLogoSuccessAction | UploadUserLogoErrorAction |
    UserNameChangingErrorAction | UserNameChangingPendingAction | UserNameChangingSuccessAction | LocationChangeAction |
    SpecialityChangeAction | EmailVerificationErrorAction | EmailVerificationPendingAction | EmailVerificationSuccessAction |
    NameChangeAction | EmailChangeAction;