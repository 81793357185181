import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import {
  fetchLoadingPending,
  fetchLoadingSuccess,
} from "../../../store/loadingIndicator/actions";
import { IAppState } from "../../../store";
import {
  getAppointmentCategoryList,
  getFilterPatientList,
  getProcedureCodeList,
} from "../../services/messageCenter";
import { toast } from "react-toastify";

interface PatientFilterModelProps {
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
  clinicId: any;
  setSelectedPatientIds: React.Dispatch<React.SetStateAction<Set<unknown>>>;
}

export const PatientFilterModel = (props: PatientFilterModelProps) => {
  const { show, setShow, clinicId, setSelectedPatientIds } = props;

  const ageOptions = [
    { value: "10_20", label: "10-20" },
    { value: "20_30", label: "20-30" },
    { value: "30_40", label: "30-40" },
    { value: "40_50", label: "40-50" },
    { value: "50_100", label: "Above 50" },
  ];

  const paymentByOption = [
    { value: "", label: "Select" },
    { value: "bycash", label: "By Cash" },
    { value: "byinsurance", label: "By Insurance" },
  ];
  const dispatch = useDispatch();
  const userDataResponse = useSelector(
    (state: IAppState) => state.userData.userDataResponse,
    shallowEqual
  );
  const [appointmentCategories, setAppointmentCategories]: any = useState([]);
  const [selectedAppointmentCategories, setSelectedAppointmentCategories]: any =
    useState([]);
  const [procedureCodes, setProcedureCodes]: any = useState([]);
  const [selectedProcedurecodes, setSelectedProcedurecodes]: any = useState([]);
  const [appointmentcategorytype, setAppointmentCategoryType] =
    useState("none");
  const [procedureCodesType, setProcedureCodesType] = useState("none");
  const [ages, setAges]: any = useState([]);
  const [payment, setPayment]: any = useState("");
  const [error, setError]: any = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async () => {
    try {
      if (
        payment === "" &&
        // ages.length === 0 &&
        appointmentcategorytype === "none" &&
        procedureCodesType === "none"
      ) {
        setError("*Please select at list one filter.");
        return;
      }
      setIsLoading(true);
      const data = await getFilterPatientList({
        clinicid: clinicId,
        age: ages.length > 0 ? ages.join(",") : "",
        payment: payment,
        procedure_category:
          appointmentcategorytype === "custom"
            ? selectedAppointmentCategories.join(",")
            : appointmentcategorytype,
        procedure_code:
          procedureCodesType === "custom"
            ? selectedProcedurecodes.join(",")
            : procedureCodesType,
      });
      setIsLoading(false);

      setSelectedPatientIds(
        new Set(data.data.map((item: any) => item.toString()))
      );

      if (data && data.error === false) {
        setShow(false);
        return;
      }
    } catch (err) {
      setIsLoading(false);
      setShow(false);
      toast.warn("Something went wrong.");
    }
  };

  const fetchAppointmentCategoryList = () => {
    dispatch(fetchLoadingPending());
    getAppointmentCategoryList({
      clinicid: userDataResponse.clinics[0].id,
    })
      .then((success: { error: boolean; data: any }) => {
        dispatch(fetchLoadingSuccess(false));
        if (success && success.error === false) {
          setAppointmentCategories(success.data);

          if (appointmentcategorytype === "all") {
            let values: any = [];
            for (let i = 0; i < success.data.length; i++) {
              values.push(success.data[i].procedure_code_category);
            }
            setSelectedAppointmentCategories(values);
          }
        }
      })
      .catch((err) => {
        dispatch(fetchLoadingSuccess(false));
      });
  };

  const fetchProcedureCode = () => {
    dispatch(fetchLoadingPending());
    getProcedureCodeList({
      clinicid: userDataResponse.clinics[0].id,
      category: selectedAppointmentCategories.join(","),
      type: appointmentcategorytype,
    })
      .then((success) => {
        dispatch(fetchLoadingSuccess(false));
        if (success && success.error === false) {
          if (success.data && success.data[0]) {
            setProcedureCodes(success.data[0]);

            if (procedureCodesType === "all") {
              let values: any = [];
              for (let i = 0; i < success.data.length; i++) {
                values.push(success.data[i].procedure_code_sikkaId);
              }
              setSelectedProcedurecodes(values);
            }
          }
        }
      })
      .catch((err) => {
        dispatch(fetchLoadingSuccess(false));
      });
  };

  useEffect(() => {
    fetchProcedureCode();
  }, [selectedAppointmentCategories, appointmentcategorytype]);

  useEffect(() => {
    fetchProcedureCode();
  }, [procedureCodesType]);
  useEffect(() => {
    fetchAppointmentCategoryList();
  }, []);

  return (
    <Modal
      className="camp-popups-sec"
      show={show}
      onHide={() => setShow(false)}
    >
      <div className="modal d-block  position-relative">
        <div className="modal-header">
          <div>
            <h5 className="modal-title mb-0">Filter</h5>
          </div>
        </div>
        <div className="modal-body">
          <div className="input-box">
            <div className="row mb-4">
              {/* <div className="col-6">
                <label htmlFor="campaignName" className="form-label fw-bold">
                  Age
                </label>
                <Select
                  aria-label="Select category"
                  id="age"
                  name="age"
                  options={ageOptions}
                  isMulti
                  onChange={(event: any) => {
                    console.log("event", event);
                    const selectedOptions = Array.from(event).map(
                      (option: any) => option.value
                    );
                    console.log("selectedOptions", selectedOptions);
                    setAges(selectedOptions);
                    setError("");
                  }}
                ></Select>
              </div> */}
              <div className="col-6">
                <label htmlFor="campaignName" className="form-label fw-bold">
                  Payment
                </label>
                <Select
                  aria-label="Select category"
                  id="paymentmode"
                  name="paymentmode"
                  options={paymentByOption}
                  onChange={(selectedOption: any) => {
                    console.log("changed value", selectedOption);
                    setPayment(selectedOption.value);
                    setError("");
                  }}
                ></Select>
              </div>
            </div>

            <div className="row mb-4">
              <div className="col-6">
                <label htmlFor="category" className="form-label fw-bold">
                  Appointment Category
                </label>
                <div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="appointmentcategorytype"
                      id="allcategory"
                      value="all"
                      onChange={() => {
                        setAppointmentCategoryType("all");
                        setError("");
                      }}
                    />
                    <label className="form-check-label" htmlFor="allcategory">
                      All
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="appointmentcategorytype"
                      id="customcategory"
                      value="custom"
                      onChange={() => {
                        setAppointmentCategoryType("custom");
                        setError("");
                      }}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="customcategory"
                    >
                      Custom
                    </label>
                  </div>
                </div>
                <select
                  multiple={true}
                  className="form-control"
                  aria-label="Select Appointment Category"
                  id="appointmentcategory"
                  disabled={
                    appointmentcategorytype === "all" ||
                    appointmentcategorytype === "none"
                  }
                  name="appointmentcategory"
                  onChange={(event) => {
                    const selectedOptions = Array.from(
                      event.target.selectedOptions
                    ).map((option) => option.value);
                    console.log("selectedOptions", selectedOptions);
                    setSelectedAppointmentCategories(selectedOptions);
                  }}
                >
                  {appointmentCategories &&
                    appointmentCategories.length > 0 &&
                    appointmentCategories.map((category: any) => {
                      return (
                        <option
                          key={category.procedure_code_category}
                          value={category.procedure_code_category}
                        >
                          {category.procedure_code_category}
                        </option>
                      );
                    })}
                </select>
              </div>
              <div className="col-6">
                <label htmlFor="category" className="form-label fw-bold">
                  Procedure Codes
                </label>
                <div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="procedurecodestype"
                      id="allcodetype"
                      value="all"
                      onChange={() => {
                        setProcedureCodesType("all");
                      }}
                    />
                    <label className="form-check-label" htmlFor="allcodetype">
                      All
                    </label>
                  </div>

                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="procedurecodestype"
                      id="customcodetype"
                      value="custom"
                      onChange={() => {
                        setProcedureCodesType("custom");
                      }}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="customcodetype"
                    >
                      Custom
                    </label>
                  </div>
                </div>
                <select
                  multiple={true}
                  className="form-control"
                  aria-label="Select Procedure Codes"
                  id="procedurecodes"
                  name="procedurecodes"
                  disabled={
                    procedureCodesType === "all" ||
                    procedureCodesType === "none"
                  }
                  onChange={(event) => {
                    const selectedOptions = Array.from(
                      event.target.selectedOptions
                    ).map((option) => option.value);
                    console.log("selectedOptions", selectedOptions);
                    setSelectedProcedurecodes(selectedOptions);
                  }}
                >
                  {procedureCodes &&
                    procedureCodes.length > 0 &&
                    procedureCodes.map((data: any) => {
                      return (
                        <option
                          key={data.procedure_code_sikkaId}
                          value={data.procedure_code_sikkaId}
                        >
                          {data.procedure_code_sikkaId}
                        </option>
                      );
                    })}
                </select>
              </div>
              {error !== "" && <div className="is-invalid">{error}</div>}
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              disabled={isLoading}
              className="btn btn-border"
              data-bs-dismiss="modal"
              onClick={() => setShow(false)}
            >
              Cancel
            </button>
            <button
              disabled={isLoading}
              type="submit"
              className="btn btn-primary"
              onClick={handleSubmit}
            >
              {isLoading && (
                <span
                  className="spinner-border spinner-border-sm me-1"
                  role="status"
                  aria-hidden="true"
                ></span>
              )}
              Apply
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
