import React, { useEffect, useMemo, useState } from 'react'
import Pagination from 'react-js-pagination';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { IAppState } from '../../../store';
import { MyScheduleHeader } from '../../components/mySchedule/MyScheduleHeader';
import { editAppointment, getAppointments } from '../../services/appointment';
import configs from '../../configs/apiConfigs';
import moment from 'moment';
import { Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { RequestForMedicalHistoryComp } from '../../components/mySchedule/RequestMedicalHistory';
import { storeChatData } from '../../../store/chat/action';
import { CancelAppointmentModal } from '../../components/mySchedule/CancelAppointmentModal';
import { AppointmentEditModal } from '../../components/mySchedule/AppointmentEditModal';
import { ScheduleReScheduleModal } from './ScheduleReScheduleModal';
import firebaseInstance from '../../helpers/firebase';
import { CHAT } from '../../../constants';
import { DigitalDoc } from '../../components/digitaldoc/DigitalDoc';
import { confirmAlert } from 'react-confirm-alert';
import { toast } from 'react-toastify';
import { callWaitlistApi } from '../../../store/appointment/actions';
import CONSTANT_LABELS from '../../../constants/labels';
import { removeCountryCode } from '../../../utils/global';
import firebase from 'firebase';
import { NoteModal } from '../../../components/note/NoteModal';
import ASAPIcon from "../../assets/images/asap.png";
let subscriptionToAppointmentChange: any = null;
const db = firebase.firestore();
export const Requested = () => {
    const [providerList, setProviderList] = useState([]);
    const [selectedProvider, setSelectedProvider] = useState('');
    const [page, setPage] = useState(1);
    const [results, setResults] = useState(300);
    const [totalCount, setTotalCount] = useState(0);
    const [appointmentItemList, setAppointmentItemList] = useState([]);
    const userData = useSelector((state: IAppState) => state.userData.userDataResponse, shallowEqual);
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [showCancelModal, setShowCancelModal] = useState(false);
    const [currentActiveModalAppointment, setCurrentActiveModalAppointment]: any = useState(null);
    const [isRefreshing, setIsRefreshing] = useState(false);
    const [showAppointmentEditModal, setShowAppointmentEditModal] = useState(false);
    const [showScheduleReScheduleModal, setShowScheduleReScheduleModal] = useState(false);
    const [digitalDocShow, setShowDigitalDocShow] = useState(false);
    const [name, setName] = useState('');
    const [date, setDate] = useState('');
    const [showNoteModal, setShowNoteModal] = useState(false);
    useEffect(() => {
        return () => {
            if (subscriptionToAppointmentChange) subscriptionToAppointmentChange();
        }
    }, []);
    useEffect(() => {
        if (userData && userData.clinics && userData.clinics[0] && userData.status && userData.status.error === false && userData.status.msg !== "") {
            let providers = userData.clinics[0].provider;
            providers = providers.filter((item: any) => item.onBoarding.status === 1 && item.enableAppointment === "1");
            setProviderList(providers);
            listenRealtimeAppointmentChange();
        }
    }, [userData]);
    useEffect(() => {
        fecthAppointmentListAsync();
    }, [selectedProvider, page, results, date]);
    useEffect(() => {
        if (name.length > 3) {
            fecthAppointmentListAsync();
        } else if (name === '') {
            fecthAppointmentListAsync();
        }
    }, [name]);
    const fecthAppointmentListAsync = async (realTime = false) => {
        try {
            let modelType = 'clinic';
            let Id = userData.clinics[0].uniqueId;
            if (selectedProvider !== '') {
                Id = selectedProvider;
                modelType = 'doctor';
            }
            const request = {
                appointmentType: 'requests',
                modelType: modelType,
                page: page,
                results: results,
                Id: Id
            };
            if (name !== '' && name.length > 3) {
                Object.assign(request, { fullname: name });
            }
            if (date !== '') {
                Object.assign(request, { searchdate: date });
            }
            if (realTime === true) {
                setIsRefreshing(true);
            } else {
                setIsLoading(true);
            }
            const response = await getAppointments(request);
            if (response && response.status && response.status.error === false) {
                setAppointmentItemList(response.appointments);
                if (page === 1) {
                    setTotalCount(response.total);
                }
            }
            if (realTime === true) {
                setIsRefreshing(false);
            } else {
                setIsLoading(false);
            }
        } catch (err) {
            if (realTime === true) {
                setIsRefreshing(false);
            } else {
                setIsLoading(false);
            }
            console.log(err);
        }
    }
    const handlePageChange = (page: any) => {
        setPage(page);
    }
    const addChatPin = (item: any) => {
        dispatch(storeChatData({
            ...item,
            fetched: true,
            pinType: "chat",
            openPin: true,
            appointmentId: item.uniqueid,
            sendToCId: item.clinic.uniqueid
        }));
    }
    const cancelAppointment = (item: any) => {
        setShowCancelModal(true);
        setCurrentActiveModalAppointment(item);
    }
    const handleEditAppointmentClick = (item: any) => {
        setShowAppointmentEditModal(true);
        setCurrentActiveModalAppointment(item);
    }
    const handleScheduleAppointmentClick = (item: any) => {
        setShowScheduleReScheduleModal(true);
        setCurrentActiveModalAppointment(item);
    }
    const listenRealtimeAppointmentChange = () => {
        subscriptionToAppointmentChange && subscriptionToAppointmentChange();
        subscriptionToAppointmentChange = firebaseInstance.db.collection(configs.CHAT_COLLECTION).where('actionType', 'in', [CHAT.ACTION_TYPE.STATUS])
            .where('sendToCId', '==', userData.clinics[0].uniqueId)
            .onSnapshot((snapshot: any) => {
                fecthAppointmentListAsync(true);
            });
    }
    const handleDigitalDocClick = (appointment: any) => {
        setCurrentActiveModalAppointment(appointment);
        setShowDigitalDocShow(true);
    }
    const markApptDone = async (appointment: any, status = undefined) => {
        try {
            confirmAlert({
                title: '',
                message: appointment.type === "guest" ? "Are you sure you want to mark appointment as cancelled?" : "Are you sure you want to mark appointment as done?",
                buttons: [
                    {
                        label: 'Yes',
                        className: 'btn btn-outline-primary',
                        onClick: async () => {
                            setIsRefreshing(true);
                            const response = await editAppointment({
                                status: status,
                                type: "archive",
                                guest: appointment.type === "guest" ? true : false
                            }, userData.clinics[0].uniqueId, appointment.uniqueid);
                            setIsRefreshing(false);
                            if (response && response.status && response.status.error === false) {
                                fecthAppointmentListAsync(true);
                                dispatch(callWaitlistApi());
                            }
                        }
                    },
                    {
                        label: 'No',
                        className: 'btn btn-outline-primary',
                        onClick: () => {

                        }
                    }
                ]
            });
        } catch (err: any) {
            setIsRefreshing(false);
            if (err.msg) {
                toast.error(err.msg);
            } else {
                toast.error('Mark appt done failed.');
            }
        }
    }

    const addToWaitlist = async (appointment: any) => {
        try {
            const response = await editAppointment({
                type: "waitlist",
                guest: appointment.type === "guest" ? true : false
            }, userData.clinics[0].uniqueId, appointment.uniqueid);
            if (response && response.status && response.status.error === false) {
                setAppointmentItemList(appointmentItemList.filter((item: any) => {
                    return item.uniqueid !== appointment.uniqueid;
                }));
                dispatch(callWaitlistApi());
            }
        } catch (err) {
            console.log("err", err);
        }
    };
    const handleShowNote = (appointment: any) => {
        setCurrentActiveModalAppointment(appointment);
        setShowNoteModal(true);
    }
    return (
        <React.Fragment>
            <MyScheduleHeader onFetchAppointment={fecthAppointmentListAsync}></MyScheduleHeader>
            <section className="schedule-sec waitlist-box px-3">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <table className="table waitlist-table">
                                <thead>
                                    <tr>
                                        <th scope="col">
                                            <div>NAME</div>
                                            <div className="d-flex">
                                                <input className="form-control form-control-sm" placeholder="Search by name" value={name} onChange={(e) => {
                                                    setPage(1);
                                                    setName(e.target.value);
                                                }} />
                                                <button className="btn btn-sm btn-secondary ms-2" onClick={(e) => {
                                                    setPage(1);
                                                    setName('');
                                                }}><i className="bi bi-x"></i></button>
                                            </div>
                                        </th>
                                        <th scope="col">
                                            <div>Provider</div>
                                            {
                                                useMemo(() => {
                                                    return (
                                                        <select className="form-select form-select-sm" style={{ width: "200px" }} value={selectedProvider} onChange={(e) => {
                                                            setPage(1);
                                                            setSelectedProvider(e.target.value);
                                                        }}>
                                                            <option value=''>All provider</option>
                                                            {
                                                                providerList.map((value: any, index) => {
                                                                    return (
                                                                        <option value={value.uniqueid} key={value.uniqueid + "provider"}>{value.fullname}</option>
                                                                    )
                                                                })
                                                            }
                                                        </select>
                                                    )
                                                }, [providerList, selectedProvider])
                                            }
                                        </th>
                                        <th scope="col">RECEIVED ON</th>
                                        <th scope="col">
                                            <div>APPOINTMENT REQUESTED ON</div>
                                            <div className="d-flex">
                                                <input className="form-control form-control-sm" type="date" id="datePicker" name="dateSchedule" value={date} onChange={(e) => {
                                                    setPage(1);
                                                    setDate(e.target.value);
                                                }}></input>
                                                <button className="btn btn-sm btn-secondary ms-2" onClick={(e) => {
                                                    setPage(1);
                                                    setDate('');
                                                    fecthAppointmentListAsync(true);
                                                }}><i className="bi bi-x"></i></button>
                                            </div>
                                        </th>
                                        {
                                            <th scope="col">Lead</th>
                                        }
                                        <th className="text-end" scope="col" style={{ width: "180px" }}>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        isLoading && !isRefreshing &&
                                        <tr className="spinner-border" role="status"></tr>
                                    }
                                    {
                                        isRefreshing && !isLoading &&
                                        <tr className="spinner-border" role="status"></tr>
                                    }
                                    {
                                        isRefreshing && isLoading &&
                                        <tr className="spinner-border" role="status"></tr>
                                    }
                                    {
                                        useMemo(() => {
                                            if (!isLoading && appointmentItemList.length === 0) {
                                                return <tr>No records found</tr>
                                            }
                                            if (userData && userData.clinics[0] && !isLoading) {
                                                return (
                                                    <>
                                                        {
                                                            appointmentItemList.map((item: any) => {
                                                                return (
                                                                    <ItemRow item={item} key={item.uniqueid}
                                                                        addChatPin={addChatPin}
                                                                        cancelAppointment={cancelAppointment}
                                                                        handleEditAppointmentClick={handleEditAppointmentClick}
                                                                        handleScheduleAppointmentClick={handleScheduleAppointmentClick}
                                                                        handleDigitalDocClick={handleDigitalDocClick}
                                                                        userData={userData}
                                                                        markApptDone={markApptDone}
                                                                        addToWaitlist={addToWaitlist}
                                                                        handleShowNote={handleShowNote}
                                                                    ></ItemRow>
                                                                )
                                                            })
                                                        }
                                                    </>
                                                )
                                            }
                                        }, [appointmentItemList, isLoading])
                                    }
                                </tbody>
                            </table>
                            <div className="d-flex justify-content-end">
                                <Pagination
                                    itemClass="page-item"
                                    linkClass="page-link"
                                    hideDisabled
                                    activePage={page}
                                    itemsCountPerPage={results}
                                    totalItemsCount={Number(totalCount)}
                                    onChange={handlePageChange}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Appointment Cancel Modal */}
            {
                useMemo(() => {
                    if (currentActiveModalAppointment && showCancelModal) {
                        return (
                            <CancelAppointmentModal appointment={currentActiveModalAppointment}
                                hideModal={() => {
                                    setShowCancelModal(false);
                                }}
                                fetchAppointment={fecthAppointmentListAsync}
                            ></CancelAppointmentModal>
                        )
                    }
                }, [currentActiveModalAppointment, showCancelModal])
            }
            {/* Appointment Edit Modal */}
            {
                useMemo(() => {
                    if (currentActiveModalAppointment && showAppointmentEditModal) {
                        return (
                            <AppointmentEditModal appointment={currentActiveModalAppointment}
                                hideModal={() => {
                                    setShowAppointmentEditModal(false);
                                }}
                                fetchAppointment={fecthAppointmentListAsync}
                            ></AppointmentEditModal>
                        )
                    }
                }, [currentActiveModalAppointment, showAppointmentEditModal])
            }
            {
                useMemo(() => {
                    if (currentActiveModalAppointment && showScheduleReScheduleModal) {
                        return (
                            <ScheduleReScheduleModal appointment={currentActiveModalAppointment}
                                hideModal={() => {
                                    setShowScheduleReScheduleModal(false);
                                }}
                                fetchAppointment={fecthAppointmentListAsync}
                                isReschedule={false}
                            ></ScheduleReScheduleModal>
                        )
                    }
                }, [currentActiveModalAppointment, showScheduleReScheduleModal])
            }
            {
                useMemo(() => {
                    if (digitalDocShow && currentActiveModalAppointment) {
                        return (
                            <DigitalDoc
                                appointment={currentActiveModalAppointment}
                                hideModal={() => {
                                    setShowDigitalDocShow(false);
                                }}
                                fetchAppointment={fecthAppointmentListAsync}
                            ></DigitalDoc>
                        )
                    }
                }, [digitalDocShow, currentActiveModalAppointment])
            }
            {
                showNoteModal &&
                <NoteModal
                    data={currentActiveModalAppointment}
                    handleCancel={() => {
                        setShowNoteModal(false);
                    }} handleOk={() => {
                        setShowNoteModal(false);
                        toast.success("Note added successfully");
                    }}
                ></NoteModal>
            }
        </React.Fragment>
    );
}

const ItemRow = ({ item, addChatPin, cancelAppointment, handleEditAppointmentClick, handleScheduleAppointmentClick,
    handleDigitalDocClick, addToWaitlist,
    userData, markApptDone, handleShowNote }: any) => {
    let takeWritebackBool = false;
    let apptScheduled = true;
    let apptReSchedule = true;
    let apptConfirmPatient = true;
    let apptComplete = true;
    let apptEdit = true;
    let apptCancel = true;
    let apptPMSStatus = true;
    let tempWriteBack = false;
    if (userData && userData.clinics && userData.clinics[0] &&
        userData.clinics[0].tempwriteBack === "on") {
        tempWriteBack = true;
    }
    if (userData?.accessEnabled?.pms_integration === true) {
        if (userData.clinics[0].writeBack === "off") {
            apptScheduled = false;
            apptReSchedule = true; // enabled for special case of SIKKA status sync issue
            apptConfirmPatient = false;
            apptComplete = true; // enabled for special case of SIKKA status sync issue
            apptEdit = true; // enabled for special case of SIKKA status sync issue
            apptCancel = true; //enabled for special case of SIKKA status sync issue
            apptPMSStatus = false;
        } else if (userData.clinics[0].writeBack === "on" &&
            userData.clinics[0].patientwriteback === "off" &&
            userData.clinics[0].appointmentwriteback === "off" &&
            userData.clinics[0].statuswriteback === "off") {
            apptScheduled = false;
            apptReSchedule = false;
            apptConfirmPatient = false;
            apptComplete = false;
            apptEdit = false;
            apptCancel = false;
            apptPMSStatus = false;
        } else if (userData.clinics[0].writeBack === "on" &&
            userData.clinics[0].patientwriteback === "on" &&
            userData.clinics[0].appointmentwriteback === "off" &&
            userData.clinics[0].statuswriteback === "off") {
            apptScheduled = false;
            apptReSchedule = false;
            apptConfirmPatient = false;
            apptComplete = false;
            apptEdit = false;
            apptCancel = false;
            apptPMSStatus = false;
        } else if (userData.clinics[0].writeBack === "on" &&
            userData.clinics[0].patientwriteback === "on" &&
            userData.clinics[0].appointmentwriteback === "off" &&
            userData.clinics[0].statuswriteback === "on") {
            apptScheduled = false;
            apptReSchedule = false;
            apptEdit = false;
        } else if (userData.clinics[0].writeBack === "on" &&
            userData.clinics[0].patientwriteback === "off" &&
            userData.clinics[0].appointmentwriteback === "off" &&
            userData.clinics[0].statuswriteback === "on") {
            apptScheduled = false;
            apptReSchedule = false;
            apptEdit = false;
        } else if (userData.clinics[0].writeBack === "on" &&
            userData.clinics[0].patientwriteback === "off" &&
            userData.clinics[0].appointmentwriteback === "off" &&
            userData.clinics[0].statuswriteback === "off") {
            apptScheduled = false;
            apptReSchedule = false;
            apptConfirmPatient = false;
            apptComplete = false;
            apptEdit = false;
            apptCancel = false;
            apptPMSStatus = false;
        }
    }
    if (userData.clinics[0] &&
        userData?.accessEnabled?.pms_integration === true &&
        (userData.clinics[0].writeBack === "on") &&
        item.metadata && item.metadata.pms && item.metadata.pms.writeBackStatus && item.metadata.pms.writeBackStatus === "Success") {
        takeWritebackBool = true;
    } else if (userData.clinics[0] &&
        userData?.accessEnabled?.pms_integration === true &&
        (userData.clinics[0].writeBack === "off")) {
        takeWritebackBool = true; //enabled for special case sikka sync issue.
    } else if (userData.clinics[0] &&
        userData?.accessEnabled?.pms_integration === false &&
        userData.clinics[0].writeBack === "off" &&
        (!(item.metadata && item.metadata.pms && item.metadata.pms.writeBackStatus) ||
            (item.metadata && item.metadata.pms && item.metadata.pms.writeBackStatus === "Success"))) {
        takeWritebackBool = true;
    }
    return (
        <tr className={"table-row have-msg"} key={item.uniqueid}>
            <td>
                <span className="d-block fw-medium p-0 text-capitalize">
                    {
                        (item.dependant && item.dependant.fname && item.dependant.lname) ?
                            (item.dependant.fname + ' ' + item.dependant.lname).substring(0, 15) : ''
                    }
                </span>
                <span className="d-block text-muted">
                    {
                        item.dependant.phone && item.dependant.phone !== "" ? (CONSTANT_LABELS.PLUS_ONE + removeCountryCode(item.dependant.phone)) : item.dependant.email
                    } {item.type === "guest" ? '(Guest)' : ""}
                </span>
            </td>
            <td>
                <div className="d-flex p-0">
                    <div className="avatar flex-shrink-0 avatar-lg border rounded-circle me-3">
                        {
                            (item.doctor && item.doctor.photo && item.doctor.photo.name &&
                                item.doctor.photo.type) ?
                                <img src={configs.IMAGE_URL + 'doctor/' +
                                    item.doctor.photo.name + '-200x200.' + item.doctor.photo.type} alt="profile image" className="img-fluid" />
                                :
                                <>{item.doctor.fullname.split(" ")[0] ? item.doctor.fullname.split(" ")[0].charAt(0) : ''}
                                    {item.doctor.fullname.split(" ")[1] ? item.doctor.fullname.split(" ")[1].charAt(0) : ''}</>
                        }
                    </div>
                    <div className="">
                        <span className="d-block fw-medium charlmt"> {(item.doctor && item.doctor.fullname) ?
                            item.doctor.fullname : ''}</span>
                        <small className="text-muted">{item.appointmentType ? item.appointmentType : ''}</small>
                        {
                            (apptEdit) &&
                            <button className="ms-2 btn p-0 btn-link"
                                type="button"
                                onClick={(e) => { handleEditAppointmentClick(item); }}>
                                Edit</button>
                        }

                    </div>
                </div>
                <div style={{
                    marginLeft: 56
                }}>
                    <small className="text-muted"><strong>Consultation:</strong> {item.consultationType ? item.consultationType : '-'}</small>
                </div>
            </td>
            <td>
                {
                    (item && item.createdAt) ?
                        <small className="d-block p-0">{moment.utc(item.createdAt).local().format("MMM DD, YYYY - LT")}</small> :
                        '-'
                }
            </td>
            <td>
                {
                    (item && item.date && item.time) &&
                    <small className="d-block p-0">{moment(item.date + " " + item.time, 'YYYY-MM-DD LT').format("MMM DD, YYYY - LT")}</small>
                }
                {
                    (apptScheduled) &&
                    <button className="btn p-0 btn-link" type="button" onClick={() => {
                        handleScheduleAppointmentClick(item);
                    }}>Schedule</button>
                }
            </td>
            {
                <td>
                    {
                        item?.isNewLead ? <span className="badge rounded-pill bg-info">New</span> : <span className="badge rounded-pill bg-dark">Existing</span>
                    }
                </td>
            }

            <td className="text-end">
                {
                    item.type === "guest" &&
                    <OverlayTrigger
                        placement="auto"
                        overlay={
                            <Tooltip id="tooltip">ASAP</Tooltip>
                        }
                    >
                        <button className="btn mx-1 p-1"
                            type="button"
                            onClick={(e) => { }}>
                            <img src={ASAPIcon} height={20} width={20} /></button>
                    </OverlayTrigger>
                }
                {
                    item.dependant && !item.dependant.phone && !item.patient.phone &&
                    <OverlayTrigger
                        placement="auto"
                        overlay={
                            <Tooltip id="tooltip">Manual followup</Tooltip>
                        }
                    >
                        <button className="btn mx-1 p-1"
                            type="button"
                            onClick={(e) => { }}>
                            <i className="bi bi-telephone"></i></button>
                    </OverlayTrigger>
                }
                {
                    item.dependant && (item.dependant.isSignInUser === true || item.dependant.phone) &&
                    <OverlayTrigger
                        placement="auto"
                        overlay={
                            <Tooltip id="tooltip">
                                Quick chat
                            </Tooltip>
                        }
                    >
                        <button className="btn mx-1 p-1"
                            type="button"
                            onClick={(e) => { addChatPin(item); }}>
                            <i className="bi bi-chat-right-text"><span className="top-badges-yellow"></span></i></button>
                    </OverlayTrigger>
                }
                {
                    (item.dependant && item.dependant.isSignInUser === true && userData.accessEnabled?.digitaldoc === true) &&
                    <OverlayTrigger placement="auto" overlay={(props: any) => {
                        return (
                            <Tooltip id="button-tooltip" {...props}>Digital forms</Tooltip>
                        )
                    }}>
                        <button onClick={() => {
                            handleDigitalDocClick(item);
                        }} className="btn mx-1 p-1">
                            <i className="bi bi-file-medical"></i>
                        </button>
                    </OverlayTrigger>
                }
                {
                    <OverlayTrigger
                        placement="auto"
                        overlay={
                            <Tooltip id="tooltip">
                                Cancel appointment
                            </Tooltip>
                        }
                    >
                        <button className="btn mx-1 p-1"
                            type="button"
                            onClick={(e) => {
                                if (item.type === "guest") {
                                    markApptDone(item);
                                } else {
                                    cancelAppointment(item);
                                }
                            }}>
                            <i className="bi bi-calendar-x"></i>
                        </button>
                    </OverlayTrigger>
                }
                {
                    (userData?.accessEnabled?.pms_integration === true) &&
                    <>
                        <Dropdown style={{
                            display: "inline-block"
                        }}>
                            <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components" className='cursor-pointer'>
                                <i className="bi bi-patch-check"></i>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item eventKey="1" onClick={() => {
                                    markApptDone(item, "Scheduled in PMS");
                                }}>Scheduled in PMS</Dropdown.Item>
                                <Dropdown.Item eventKey="2" onClick={() => {
                                    markApptDone(item, "No Show");
                                }}>No Show</Dropdown.Item>
                                <Dropdown.Item eventKey="3" onClick={() => {
                                    markApptDone(item, "Cancelled");
                                }}>
                                    Cancelled
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </>
                }
                {
                    item?.type !== "guest" &&
                    <OverlayTrigger
                        placement="top"
                        overlay={
                            <Tooltip id="tooltip">Add to waitlist</Tooltip>
                        }
                    >
                        <button className="btn rounded-circle" onClick={() => {
                            addToWaitlist(item);
                        }}><i className="bi bi-clock-history"></i></button>
                    </OverlayTrigger>
                }
                {

                    <OverlayTrigger
                        placement="auto"
                        overlay={
                            <Tooltip id="tooltip">
                                Note
                            </Tooltip>
                        }
                    >
                        <button className="btn mx-1 p-1"
                            type="button"
                            onClick={() => {
                                handleShowNote(item);
                            }}>
                            <i className="bi bi-pencil-square"></i></button>
                    </OverlayTrigger>
                }
            </td>
        </tr >
    )
}

const CustomToggle = React.forwardRef(({ children, onClick }: any, ref: any) => (
    <a href="#" ref={ref} className="btn mx-1 p-1"
        type="button"
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}>
        {children}
    </a>
));