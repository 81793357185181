import React from 'react';
import InvoiceModal from './InvoiceModal';
import { IAppState } from '../../store';
import { connect } from 'react-redux';
import { PaymentState, PaymentBillableEntryRequest, PaymentBillableAppointmentRequest, PaymentBillableAppointmentResponse, ServicesTypeRequest, ServicesTypeResponse } from '../../store/payment/types';
import { paymentBillableEntryPending, paymentBillableAppointmentPending } from '../../store/payment/actions';
import { monthAndYear, roundNumberWithRoundingFactorDecimalFix } from '../../utils/global';
import moment from 'moment';
import * as firebase from 'firebase/app';
import 'firebase/firestore';
import configs from '../../configs/apiConfigs';
import { getCookie } from '../../utils/cookies';
import { CHAT } from '../../constants';
import { fetchLoadingPending, fetchLoadingSuccess } from '../../store/loadingIndicator/actions';
import { userLogout } from '../../store/login/actions';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import _ from 'lodash';
import { UserDataResponse } from '../../store/userData/types';
interface Props {
    paymentState: PaymentState;
    billableEntryFetch: typeof paymentBillableEntryPending;
    billintEntryAppointment: typeof paymentBillableAppointmentPending;
    invoiceType: string;
    logout: typeof userLogout;
    loadingStart: typeof fetchLoadingPending;
    loadingEnd: typeof fetchLoadingSuccess;
    history: any;
    match: any;
    userData: UserDataResponse;
}
interface State {
    viewPaymentModal: boolean;
    clinicSelect: any[];
    providerSelect: any[];
    selectedClinicSelect: any;
    selectedProviderSelect: any;
    billableAppointmentList: PaymentBillableAppointmentResponse[];
    yearMonthSelect: any[],
    selectedYearMonthSelect: any,
    invoiceModalOperation: string;
    selectedAppointment?: PaymentBillableAppointmentResponse;
    servicesType?: ServicesTypeResponse["serviceType"];
    searchName: any;
}
class AllInvoice extends React.Component<Props, State> {
    db: any;
    constructor(props: any) {
        super(props);
        this.state = {
            viewPaymentModal: false,
            clinicSelect: [],
            providerSelect: [],
            selectedClinicSelect: '',
            selectedProviderSelect: '',
            billableAppointmentList: [],
            yearMonthSelect: [],
            selectedYearMonthSelect: '',
            invoiceModalOperation: '',
            searchName: ''
        }
        this.handlePaymentRequest = this.handlePaymentRequest.bind(this);
    }
    componentDidMount() {
        this.db = firebase.firestore();
        if (this.props.paymentState.fetchInitPaymentResponse && this.props.paymentState.fetchInitPaymentResponse.clinics) {
            this.setState({
                clinicSelect: this.props.paymentState.fetchInitPaymentResponse.clinics.map((value, index) => {
                    return {
                        value: value.uniqueId,
                        label: value.name
                    }
                })
            }, () => {
                this.setState({ selectedClinicSelect: this.state.clinicSelect[0].value }, () => {
                    this.setDoctorListByClinic(this.state.selectedClinicSelect);
                });
            });
        }

        //services type
        if (this.props.paymentState && this.props.paymentState.servicesTypeResponse) {
            this.setState({ servicesType: this.props.paymentState.servicesTypeResponse.serviceType });
        }
    }
    setDoctorListByClinic(clinicUniqueId: string) {
        if (this.props.paymentState.fetchInitPaymentResponse && this.props.paymentState.fetchInitPaymentResponse.clinics) {
            const clinic = this.props.paymentState.fetchInitPaymentResponse.clinics.find((value, index) => {
                return value.uniqueId === clinicUniqueId;
            });
            console.log(clinic);
            if (clinic) {
                const doctorFiltered = clinic.doctors.map((value) => {
                    return {
                        value: value.provider,
                        label: value.fullname
                    }
                });
                const doctors = [{ value: 'all', label: 'All Providers' }].concat(doctorFiltered);
                this.setState({
                    providerSelect: doctors,
                    selectedProviderSelect: 'all'
                }, () => {
                    this.props.billableEntryFetch({ type: 'clinic', uniqueId: this.state.selectedClinicSelect });
                });
            }
        }
    }
    shouldComponentUpdate(nextProps: Props, nextState: State) {
        if (nextProps !== this.props) {
            if (nextProps.paymentState !== this.props.paymentState) {
                if (nextProps.paymentState.error !== null) {

                } else if (
                    nextProps.paymentState.paymentBillableEntryResponse &&
                    nextProps.paymentState.paymentBillableEntryResponse !== this.props.paymentState.paymentBillableEntryResponse
                    && nextProps.paymentState.paymentBillableEntryResponse.billableMonthsByYear && nextProps.paymentState.paymentBillableEntryResponse.billableYears) {
                    monthAndYear(nextProps.paymentState.paymentBillableEntryResponse).then((success) => {
                        this.setState({
                            yearMonthSelect: success,
                            selectedYearMonthSelect: success[0] ? success[0].value : ''
                        }, () => {
                            const type = this.state.selectedProviderSelect === 'all' ? 'clinic' : 'provider';
                            const uniqueId = type === 'clinic' ? this.state.selectedClinicSelect : this.state.selectedProviderSelect;
                            const year = this.state.selectedYearMonthSelect.split("-")[0];
                            const month = this.state.selectedYearMonthSelect.split("-")[1];
                            if (year && month) {
                                this.props.billintEntryAppointment({
                                    month: month,
                                    year: year,
                                    uniqueId: uniqueId,
                                    type: type,
                                    invoiceType: this.props.invoiceType,
                                    fullname: this.state.searchName
                                });
                            }
                        });
                    });
                } else if (nextProps.paymentState.paymentBillableAppointmentResponse &&
                    nextProps.paymentState.paymentBillableAppointmentResponse !== this.props.paymentState.paymentBillableAppointmentResponse) {
                    this.setState({
                        billableAppointmentList: _.sortBy(nextProps.paymentState.paymentBillableAppointmentResponse, (value) => {
                            return new Date(value.metadata.appointment.verifiedOn);
                        }).filter((value) => {
                            if (this.props.invoiceType === "outstanding") {
                                return !value.billingdata.clinicToPatientBilling || value.billingdata?.clinicToPatientBilling?.status.toLocaleLowerCase() !== "paid" || false;
                            } else if (this.props.invoiceType === "paid") {
                                return value.billingdata?.clinicToPatientBilling?.status.toLocaleLowerCase() === "paid" || false
                            }
                            return true;
                        }).reverse()
                    });
                } else if (nextProps.paymentState.servicesTypeResponse &&
                    nextProps.paymentState.servicesTypeResponse !== this.props.paymentState.servicesTypeResponse &&
                    nextProps.paymentState.pending === false) {
                    this.setState({ servicesType: nextProps.paymentState.servicesTypeResponse.serviceType });
                }
            }
        }
        return true;
    }
    searchByName = (name: any) => {
        this.setState({ searchName: name });
        const type = this.state.selectedProviderSelect === 'all' ? 'clinic' : 'provider';
        const uniqueId = type === 'clinic' ? this.state.selectedClinicSelect : this.state.selectedProviderSelect;
        const year = this.state.selectedYearMonthSelect.split("-")[0];
        const month = this.state.selectedYearMonthSelect.split("-")[1];
        if (name.length > 2) {
            if (year && month) {
                this.props.billintEntryAppointment({
                    month: month,
                    year: year,
                    uniqueId: uniqueId,
                    type: type,
                    invoiceType: this.props.invoiceType,
                    fullname: name
                });
            }
        } else if (name.length === 0) {
            if (year && month) {
                this.props.billintEntryAppointment({
                    month: month,
                    year: year,
                    uniqueId: uniqueId,
                    type: type,
                    invoiceType: this.props.invoiceType,
                    fullname: ""
                });
            }
        }
    }
    render() {
        console.log("state", this.state);
        return (
            <section className="contactless-sec px-3 pt-4">
                <div className="container">
                    {
                        this.props.paymentState.pending === true &&
                        <div className="spinner-border" role="status"></div>
                    }
                    <div className="row mb-1">
                        <div className="col-8">
                            <div className="d-flex">
                                <select className="form-select me-3" value={this.state.selectedClinicSelect} onChange={(e) => {
                                    this.setState({
                                        billableAppointmentList: [],
                                        selectedClinicSelect: e.target.value
                                    }, () => {
                                        this.setDoctorListByClinic(this.state.selectedClinicSelect);
                                    });
                                }}>
                                    {
                                        this.state.clinicSelect.map((value, index) => {
                                            return (
                                                <option value={value.value} key={index + Math.random()}>{value.label}</option>
                                            )
                                        })
                                    }
                                </select>

                                <select className="form-select me-3" value={this.state.selectedProviderSelect} onChange={(e) => {
                                    this.setState({
                                        billableAppointmentList: [],
                                        selectedProviderSelect: e.target.value
                                    }, () => {
                                        if (this.state.selectedProviderSelect === 'all') {
                                            this.props.billableEntryFetch({ type: 'clinic', uniqueId: this.state.selectedClinicSelect });
                                        } else {
                                            this.props.billableEntryFetch({ type: 'provider', uniqueId: this.state.selectedProviderSelect });
                                        }
                                    });
                                }}>
                                    {
                                        this.state.providerSelect.map((value, index) => {
                                            return (
                                                <option value={value.value} key={index + Math.random()}>{value.label}</option>
                                            )
                                        })
                                    }
                                </select>

                                <select className="form-select me-3" value={this.state.selectedYearMonthSelect} onChange={(e) => {
                                    this.setState({ selectedYearMonthSelect: e.target.value }, () => {
                                        const type = this.state.selectedProviderSelect === 'all' ? 'clinic' : 'provider';
                                        const uniqueId = type === 'clinic' ? this.state.selectedClinicSelect : this.state.selectedProviderSelect;
                                        const year = this.state.selectedYearMonthSelect.split("-")[0];
                                        const month = this.state.selectedYearMonthSelect.split("-")[1];
                                        if (year && month) {
                                            this.props.billintEntryAppointment({
                                                month: month,
                                                year: year,
                                                uniqueId: uniqueId,
                                                type: type,
                                                invoiceType: this.props.invoiceType,
                                                fullname: this.state.searchName
                                            });
                                        }
                                    });
                                }}>
                                    {
                                        this.state.yearMonthSelect.map((value, index) => {
                                            return (
                                                <option value={value.value} key={index + Math.random()}>{value.label}</option>
                                            )
                                        })
                                    }
                                </select>
                                <input type="text" className="form-control me-3" placeholder="Enter Name" value={this.state.searchName} onChange={(e: any) => { this.searchByName(e.target.value) }} />
                                {/* <button className="form-control me-3" onClick={()=>{this.searchByName(e.target.value)}}>Search</button> */}
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <div className="advances-table">
                                <table className="table table-hover">
                                    <thead>
                                        <tr>
                                            <th>Patient</th>
                                            <th>Provider</th>
                                            <th>Appt. date</th>
                                            <th>Amount</th>
                                            <th>Payment status</th>
                                            <th className="text-end" style={{ width: "165px" }}>Action</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {
                                            (this.state.billableAppointmentList && this.state.billableAppointmentList.length > 0) &&
                                            this.state.billableAppointmentList.map((value, index) => {
                                                if (this.props.invoiceType === "draft" && value.billingdata.clinicToPatientBilling && value.billingdata.clinicToPatientBilling.status === "pending" &&
                                                    !value.metadata.appointment.payment) {
                                                    return (
                                                        <tr>
                                                            {
                                                                (value.requests && value.requests.appointmentFor === "other") ?
                                                                    <td>{value.requests.dependantName + " - " + value.requests.dependantRelation}</td> :
                                                                    <td>{value.dependant.fname + ' ' + value.dependant.lname}</td>
                                                            }
                                                            <td>{value.doctor.fullname}</td>
                                                            {/* <td>{moment(value.metadata.appointment.verifiedOn, 'YYYY-MM-DD hh:mm:ss').format("MMM")} {moment(value.metadata.appointment.verifiedOn, 'YYYY-MM-DD hh:mm:ss').format("DD")}
                                                                <sup>{moment(value.metadata.appointment.verifiedOn, 'YYYY-MM-DD hh:mm:ss').format("Do") ? moment(value.metadata.appointment.verifiedOn, 'YYYY-MM-DD hh:mm:ss').format("Do").slice(-2) : ''}</sup></td> */}
                                                            <td>{moment(value.scheduledDate + " " + value.scheduledTime, 'YYYY-MM-DD LT').format("MMM DD, YYYY - LT")}</td>
                                                            <td>
                                                                {
                                                                    value.billingdata?.clinicToPatientBilling?.total ?
                                                                        <span>${roundNumberWithRoundingFactorDecimalFix(value.billingdata.clinicToPatientBilling.total, 2)}</span>
                                                                        :
                                                                        '-'
                                                                }
                                                            </td>
                                                            <td>
                                                                {
                                                                    value.billingdata?.clinicToPatientBilling?.status ?
                                                                        <div className="d-flex align-items-center">
                                                                            <span className={"badge badge-dot me-2 " +
                                                                                (value?.billingdata?.clinicToPatientBilling?.status.toLocaleLowerCase() === "paid" ? 'badge-success' : 'badge-danger')}>
                                                                            </span>
                                                                            <span className={" " +
                                                                                (value?.billingdata?.clinicToPatientBilling?.status.toLocaleLowerCase() === "paid" ? 'text-success' : 'text-danger')}>
                                                                                {
                                                                                    (value.billingdata.clinicToPatientBilling.status === "pending" && !value.metadata.appointment.payment) &&
                                                                                    <>Invoice is created</>
                                                                                }
                                                                                {
                                                                                    (value.billingdata.clinicToPatientBilling.status === "pending" && value.metadata.appointment.payment) &&
                                                                                    <>{value.billingdata.clinicToPatientBilling.status}</>
                                                                                }
                                                                                {
                                                                                    (value.billingdata.clinicToPatientBilling.status !== "pending") &&
                                                                                    <>{value.billingdata.clinicToPatientBilling.status} {
                                                                                        value.billingdata.recurring && value.billingdata.recurring.recurringStatus &&
                                                                                        <>(${roundNumberWithRoundingFactorDecimalFix(value.billingdata.recurring.paid, 2)})</>
                                                                                    }</>
                                                                                }
                                                                            </span>
                                                                        </div> :
                                                                        <div className="d-flex align-items-center">
                                                                            <span className={"badge badge-danger badge-dot me-2"}></span>
                                                                            <span className="text-danger ">Invoice not generated</span>
                                                                        </div>
                                                                }
                                                            </td>
                                                            <td className="text-end">
                                                                {
                                                                    value.billingdata.clinicToPatientBilling &&
                                                                    <>
                                                                        <OverlayTrigger
                                                                            overlay={
                                                                                <Tooltip id="tooltip">
                                                                                    View Iinvoice
                                                                                </Tooltip>
                                                                            }
                                                                        >
                                                                            <button className="btn me-2"
                                                                                onClick={(e) => {
                                                                                    if (this.props.paymentState.fetchInitPaymentResponse) {
                                                                                        const clinic = this.props.paymentState.fetchInitPaymentResponse.clinics.find((clinic) => { return clinic.uniqueId === value.clinic.uniqueid });
                                                                                        if (clinic) {
                                                                                            this.setState({
                                                                                                viewPaymentModal: true,
                                                                                                invoiceModalOperation: "view",
                                                                                                selectedAppointment: {
                                                                                                    ...value,
                                                                                                    clinic: {
                                                                                                        ...value.clinic,
                                                                                                        metadata: clinic.metadata
                                                                                                    }
                                                                                                },
                                                                                            });
                                                                                        }
                                                                                    }
                                                                                }}><i className="bi bi-eye"></i></button>
                                                                        </OverlayTrigger>
                                                                        <OverlayTrigger
                                                                            overlay={
                                                                                <Tooltip id="tooltip">
                                                                                    Edit invoice
                                                                                </Tooltip>
                                                                            }
                                                                        >
                                                                            <button className={"btn btn-sm me-2 "}
                                                                                onClick={(e) => {
                                                                                    if (this.props.paymentState.fetchInitPaymentResponse) {
                                                                                        const clinic = this.props.paymentState.fetchInitPaymentResponse.clinics.find((clinic) => { return clinic.uniqueId === value.clinic.uniqueid });
                                                                                        if (clinic) {
                                                                                            this.setState({
                                                                                                viewPaymentModal: true,
                                                                                                invoiceModalOperation: "edit",
                                                                                                selectedAppointment: {
                                                                                                    ...value,
                                                                                                    clinic: {
                                                                                                        ...value.clinic,
                                                                                                        metadata: clinic.metadata
                                                                                                    }
                                                                                                },
                                                                                            });
                                                                                        }
                                                                                    }
                                                                                }}><i className="bi bi-pencil"></i></button>
                                                                        </OverlayTrigger>
                                                                        {
                                                                            !value.metadata.appointment.payment ?
                                                                                <OverlayTrigger
                                                                                    overlay={
                                                                                        <Tooltip id="tooltip">
                                                                                            Payment request
                                                                                        </Tooltip>
                                                                                    }
                                                                                >
                                                                                    <button className={"btn btn-sm me-2"} onClick={(e) => {
                                                                                        this.handlePaymentRequest(value);
                                                                                    }}><i className="bi bi-cursor"></i></button>
                                                                                </OverlayTrigger>
                                                                                :
                                                                                <OverlayTrigger
                                                                                    overlay={
                                                                                        <Tooltip id="tooltip">
                                                                                            Resend request
                                                                                        </Tooltip>
                                                                                    }
                                                                                >
                                                                                    <button className={"btn btn-sm me-2 "} onClick={(e) => {
                                                                                        this.handlePaymentRequest(value);
                                                                                    }}><i className="bi bi-arrow-clockwise"></i></button>
                                                                                </OverlayTrigger>
                                                                        }
                                                                    </>
                                                                }
                                                            </td>
                                                        </tr>
                                                    )
                                                } else if (this.props.invoiceType !== "draft") {
                                                    if (this.props.invoiceType === "outstanding" && value.billingdata && value.billingdata.clinicToPatientBilling && value.billingdata.clinicToPatientBilling.status === "pending" && value.metadata.appointment.payment) {
                                                        return (
                                                            <tr>
                                                                {
                                                                    (value.requests && value.requests.appointmentFor === "other") ?
                                                                        <td>{value.requests.dependantName + " - " + value.requests.dependantRelation}</td> :
                                                                        <td>{value.dependant.fname + ' ' + value.dependant.lname}</td>
                                                                }
                                                                <td>{value.doctor.fullname}</td>
                                                                {/* <td>{moment(value.metadata.appointment.verifiedOn).format("MMM")} {moment(value.metadata.appointment.verifiedOn).format("DD")}
                                                                    <sup>{moment(value.metadata.appointment.verifiedOn).format("Do") ? moment(value.metadata.appointment.verifiedOn).format("Do").slice(-2) : ''}</sup></td> */}
                                                                <td>{moment(value.scheduledDate + " " + value.scheduledTime, 'YYYY-MM-DD LT').format("MMM DD, YYYY - LT")}</td>
                                                                <td>
                                                                    {
                                                                        value.billingdata?.clinicToPatientBilling?.total ?
                                                                            <span>${roundNumberWithRoundingFactorDecimalFix(value.billingdata.clinicToPatientBilling.total, 2)}</span>
                                                                            :
                                                                            '-'
                                                                    }
                                                                </td>
                                                                <td>
                                                                    {
                                                                        value.billingdata?.clinicToPatientBilling?.status ?
                                                                            <div className="d-flex align-items-center">
                                                                                <span className={"badge badge-dot me-2 " +
                                                                                    (value?.billingdata?.clinicToPatientBilling?.status.toLocaleLowerCase() === "paid" ? 'badge-success' : 'badge-danger')}></span>
                                                                                <span className={" " +
                                                                                    (value?.billingdata?.clinicToPatientBilling?.status.toLocaleLowerCase() === "paid" ? 'text-success' : 'text-danger')}>
                                                                                    {
                                                                                        (value.billingdata.clinicToPatientBilling.status === "pending" && !value.metadata.appointment.payment) &&
                                                                                        <>Invoice is created</>
                                                                                    }
                                                                                    {
                                                                                        (value.billingdata.clinicToPatientBilling.status === "pending" && value.metadata.appointment.payment) &&
                                                                                        <>{value.billingdata.clinicToPatientBilling.status}</>
                                                                                    }
                                                                                    {
                                                                                        (value.billingdata.clinicToPatientBilling.status !== "pending") &&
                                                                                        <>{value.billingdata.clinicToPatientBilling.status} {
                                                                                            value.billingdata.recurring && value.billingdata.recurring.recurringStatus &&
                                                                                            <>(${roundNumberWithRoundingFactorDecimalFix(value.billingdata.recurring.paid, 2)})</>
                                                                                        }</>
                                                                                    }
                                                                                </span>
                                                                            </div> :
                                                                            <div className="d-flex align-items-center">
                                                                                <span className={"badge badge-danger badge-dot me-2"}></span>
                                                                                <span className="text-danger ">Invoice not generated</span>
                                                                            </div>
                                                                    }
                                                                </td>
                                                                <td className="text-end">
                                                                    {
                                                                        (!value.billingdata.clinicToPatientBilling) &&
                                                                        <OverlayTrigger
                                                                            overlay={
                                                                                <Tooltip id="tooltip">
                                                                                    Create invoice
                                                                                </Tooltip>
                                                                            }
                                                                        >
                                                                            <button className="btn btn-sm me-2"
                                                                                onClick={(e) => {
                                                                                    if (this.props.paymentState.fetchInitPaymentResponse) {
                                                                                        const clinic = this.props.paymentState.fetchInitPaymentResponse.clinics.find((clinic) => { return clinic.uniqueId === value.clinic.uniqueid });
                                                                                        if (clinic) {
                                                                                            this.setState({
                                                                                                viewPaymentModal: true,
                                                                                                invoiceModalOperation: "create",
                                                                                                selectedAppointment: {
                                                                                                    ...value,
                                                                                                    clinic: {
                                                                                                        ...value.clinic,
                                                                                                        metadata: clinic.metadata
                                                                                                    }
                                                                                                },
                                                                                            });
                                                                                        }
                                                                                    }
                                                                                }}
                                                                            ><i className="bi bi-plus-square"></i>
                                                                            </button>
                                                                        </OverlayTrigger>
                                                                    }
                                                                    {
                                                                        value.billingdata.clinicToPatientBilling &&
                                                                        <>
                                                                            <OverlayTrigger
                                                                                overlay={
                                                                                    <Tooltip id="tooltip">
                                                                                        View invoice
                                                                                    </Tooltip>
                                                                                }
                                                                            >
                                                                                <button className="btn btn-sm me-2"
                                                                                    onClick={(e) => {
                                                                                        if (this.props.paymentState.fetchInitPaymentResponse) {
                                                                                            const clinic = this.props.paymentState.fetchInitPaymentResponse.clinics.find((clinic) => { return clinic.uniqueId === value.clinic.uniqueid });
                                                                                            if (clinic) {
                                                                                                this.setState({
                                                                                                    viewPaymentModal: true,
                                                                                                    invoiceModalOperation: "view",
                                                                                                    selectedAppointment: {
                                                                                                        ...value,
                                                                                                        clinic: {
                                                                                                            ...value.clinic,
                                                                                                            metadata: clinic.metadata
                                                                                                        }
                                                                                                    },
                                                                                                });
                                                                                            }
                                                                                        }
                                                                                    }}><i className="bi bi-eye"></i></button>
                                                                            </OverlayTrigger>
                                                                            <OverlayTrigger
                                                                                overlay={
                                                                                    <Tooltip id="tooltip">
                                                                                        Edit invoice
                                                                                    </Tooltip>
                                                                                }
                                                                            >
                                                                                <button className={"btn btn-sm me-2 "}
                                                                                    onClick={(e) => {
                                                                                        if (this.props.paymentState.fetchInitPaymentResponse) {
                                                                                            const clinic = this.props.paymentState.fetchInitPaymentResponse.clinics.find((clinic) => { return clinic.uniqueId === value.clinic.uniqueid });
                                                                                            if (clinic) {
                                                                                                this.setState({
                                                                                                    viewPaymentModal: true,
                                                                                                    invoiceModalOperation: "edit",
                                                                                                    selectedAppointment: {
                                                                                                        ...value,
                                                                                                        clinic: {
                                                                                                            ...value.clinic,
                                                                                                            metadata: clinic.metadata
                                                                                                        }
                                                                                                    },
                                                                                                });
                                                                                            }
                                                                                        }
                                                                                    }}><i className="bi bi-pencil"></i></button>
                                                                            </OverlayTrigger>
                                                                            {
                                                                                !value.metadata.appointment.payment ?
                                                                                    <OverlayTrigger
                                                                                        overlay={
                                                                                            <Tooltip id="tooltip">
                                                                                                Payment Request
                                                                                            </Tooltip>
                                                                                        }
                                                                                    >
                                                                                        <button className={"btn btn-sm me-2"} onClick={(e) => {
                                                                                            this.handlePaymentRequest(value);
                                                                                        }}><i className="bi bi-cursor"></i></button>
                                                                                    </OverlayTrigger>
                                                                                    :
                                                                                    <OverlayTrigger
                                                                                        overlay={
                                                                                            <Tooltip id="tooltip">
                                                                                                Resend Request
                                                                                            </Tooltip>
                                                                                        }
                                                                                    >
                                                                                        <button className={"btn btn-sm me-2 "} onClick={(e) => {
                                                                                            this.handlePaymentRequest(value);
                                                                                        }}><i className="bi bi-arrow-clockwise"></i></button>
                                                                                    </OverlayTrigger>
                                                                            }
                                                                        </>
                                                                    }
                                                                </td>
                                                            </tr>
                                                        )
                                                    } else if (this.props.invoiceType !== "outstanding") {
                                                        return (
                                                            <tr>
                                                                {
                                                                    (value.requests && value.requests.appointmentFor === "other") ?
                                                                        <td>{value.requests.dependantName + " - " + value.requests.dependantRelation}</td> :
                                                                        <td>{value.dependant.fname + ' ' + value.dependant.lname}</td>
                                                                }
                                                                <td>{value.doctor.fullname}</td>
                                                                {/* <td>{moment(value.metadata.appointment.verifiedOn, 'YYYY-MM-DD hh:mm:ss').format("MMM")} {moment(value.metadata.appointment.verifiedOn, 'YYYY-MM-DD hh:mm:ss').format("DD")}
                                                                    <sup>{moment(value.metadata.appointment.verifiedOn, 'YYYY-MM-DD hh:mm:ss').format("Do") ? moment(value.metadata.appointment.verifiedOn, 'YYYY-MM-DD hh:mm:ss').format("Do").slice(-2) : ''}</sup></td> */}
                                                                <td>{moment(value.scheduledDate + " " + value.scheduledTime, 'YYYY-MM-DD LT').format("MMM DD, YYYY - LT")}</td>
                                                                <td>
                                                                    {
                                                                        value.billingdata?.clinicToPatientBilling?.total ?
                                                                            <span>${roundNumberWithRoundingFactorDecimalFix(value.billingdata.clinicToPatientBilling.total, 2)}</span>
                                                                            :
                                                                            '-'
                                                                    }
                                                                </td>
                                                                <td>
                                                                    {
                                                                        value.billingdata?.clinicToPatientBilling?.status ?
                                                                            <div className="d-flex align-items-center">
                                                                                <span className={"badge badge-dot me-2 " +
                                                                                    (value?.billingdata?.clinicToPatientBilling?.status.toLocaleLowerCase() === "paid" ? 'badge-success' : 'badge-danger')}></span>
                                                                                <span className={" " +
                                                                                    (value?.billingdata?.clinicToPatientBilling?.status.toLocaleLowerCase() === "paid" ? 'text-success' : 'text-danger')}>
                                                                                    {
                                                                                        (value.billingdata.clinicToPatientBilling.status === "pending" && !value.metadata.appointment.payment) &&
                                                                                        <>Invoice is created</>
                                                                                    }
                                                                                    {
                                                                                        (value.billingdata.clinicToPatientBilling.status === "pending" && value.metadata.appointment.payment) &&
                                                                                        <>{value.billingdata.clinicToPatientBilling.status}</>
                                                                                    }
                                                                                    {
                                                                                        (value.billingdata.clinicToPatientBilling.status !== "pending") &&
                                                                                        <>{value.billingdata.clinicToPatientBilling.status} {
                                                                                            value.billingdata.recurring && value.billingdata.recurring.recurringStatus &&
                                                                                            <>(${roundNumberWithRoundingFactorDecimalFix(value.billingdata.recurring.paid, 2)})</>
                                                                                        }</>
                                                                                    }
                                                                                </span>
                                                                            </div> :
                                                                            <div className="d-flex align-items-center">
                                                                                <span className={"badge badge-danger badge-dot me-2"}></span>
                                                                                <span className="text-danger ">Invoice not generated</span>
                                                                            </div>
                                                                    }
                                                                </td>
                                                                <td className="text-end">
                                                                    {
                                                                        (this.props.invoiceType === "all" || this.props.invoiceType === "outstanding") &&
                                                                        <>
                                                                            {
                                                                                (!value.billingdata.clinicToPatientBilling) &&
                                                                                <OverlayTrigger
                                                                                    overlay={
                                                                                        <Tooltip id="tooltip">
                                                                                            Create Invoice
                                                                                        </Tooltip>
                                                                                    }
                                                                                >
                                                                                    <button className="btn btn-sm me-2"
                                                                                        onClick={(e) => {
                                                                                            if (this.props.paymentState.fetchInitPaymentResponse) {
                                                                                                const clinic = this.props.paymentState.fetchInitPaymentResponse.clinics.find((clinic) => { return clinic.uniqueId === value.clinic.uniqueid });
                                                                                                if (clinic) {
                                                                                                    this.setState({
                                                                                                        viewPaymentModal: true,
                                                                                                        invoiceModalOperation: "create",
                                                                                                        selectedAppointment: {
                                                                                                            ...value,
                                                                                                            clinic: {
                                                                                                                ...value.clinic,
                                                                                                                metadata: clinic.metadata
                                                                                                            }
                                                                                                        },
                                                                                                    });
                                                                                                }
                                                                                            }
                                                                                        }}
                                                                                    ><i className="bi bi-plus-square"></i>
                                                                                    </button>
                                                                                </OverlayTrigger>
                                                                            }
                                                                            {
                                                                                value.billingdata.clinicToPatientBilling &&
                                                                                <>
                                                                                    <OverlayTrigger
                                                                                        overlay={
                                                                                            <Tooltip id="tooltip">
                                                                                                View Invoice
                                                                                            </Tooltip>
                                                                                        }
                                                                                    >
                                                                                        <button className="btn btn-sm me-2"
                                                                                            onClick={(e) => {
                                                                                                if (this.props.paymentState.fetchInitPaymentResponse) {
                                                                                                    const clinic = this.props.paymentState.fetchInitPaymentResponse.clinics.find((clinic) => { return clinic.uniqueId === value.clinic.uniqueid });
                                                                                                    if (clinic) {
                                                                                                        this.setState({
                                                                                                            viewPaymentModal: true,
                                                                                                            invoiceModalOperation: "view",
                                                                                                            selectedAppointment: {
                                                                                                                ...value,
                                                                                                                clinic: {
                                                                                                                    ...value.clinic,
                                                                                                                    metadata: clinic.metadata
                                                                                                                }
                                                                                                            },
                                                                                                        });
                                                                                                    }
                                                                                                }
                                                                                            }}><i className="bi bi-eye"></i></button>
                                                                                    </OverlayTrigger>
                                                                                    <OverlayTrigger
                                                                                        overlay={
                                                                                            <Tooltip id="tooltip">
                                                                                                Edit Invoice
                                                                                            </Tooltip>
                                                                                        }
                                                                                    >
                                                                                        <button className={"btn btn-sm me-2 " + (value?.billingdata?.clinicToPatientBilling?.status === "paid" ? ' d-none' : '')}
                                                                                            onClick={(e) => {
                                                                                                if (this.props.paymentState.fetchInitPaymentResponse) {
                                                                                                    const clinic = this.props.paymentState.fetchInitPaymentResponse.clinics.find((clinic) => { return clinic.uniqueId === value.clinic.uniqueid });
                                                                                                    if (clinic) {
                                                                                                        this.setState({
                                                                                                            viewPaymentModal: true,
                                                                                                            invoiceModalOperation: "edit",
                                                                                                            selectedAppointment: {
                                                                                                                ...value,
                                                                                                                clinic: {
                                                                                                                    ...value.clinic,
                                                                                                                    metadata: clinic.metadata
                                                                                                                }
                                                                                                            },
                                                                                                        });
                                                                                                    }
                                                                                                }
                                                                                            }}><i className="bi bi-pencil"></i></button>
                                                                                    </OverlayTrigger>
                                                                                    {
                                                                                        !value.metadata.appointment.payment ?
                                                                                            <OverlayTrigger
                                                                                                overlay={
                                                                                                    <Tooltip id="tooltip">
                                                                                                        Payment Request
                                                                                                    </Tooltip>
                                                                                                }
                                                                                            >
                                                                                                <button className={"btn btn-sm me-2" + (value?.billingdata?.clinicToPatientBilling?.status === "paid" ? ' d-none' : '')} onClick={(e) => {
                                                                                                    this.handlePaymentRequest(value);
                                                                                                }}><i className="bi bi-cursor"></i></button>
                                                                                            </OverlayTrigger>
                                                                                            :
                                                                                            value.billingdata?.clinicToPatientBilling?.status !== "paid" ?
                                                                                            <OverlayTrigger
                                                                                                overlay={
                                                                                                    <Tooltip id="tooltip">
                                                                                                        Resend Request
                                                                                                    </Tooltip>
                                                                                                }
                                                                                            >
                                                                                                <button className={"btn btn-sm me-2 " + (value?.billingdata?.clinicToPatientBilling?.status === "paid" ? ' d-none' : '')} onClick={(e) => {
                                                                                                    this.handlePaymentRequest(value);
                                                                                                }}><i className="bi bi-arrow-clockwise"></i></button>
                                                                                            </OverlayTrigger> : null
                                                                                    }
                                                                                </>
                                                                            }
                                                                        </>
                                                                    }
                                                                    {
                                                                        this.props.invoiceType === "paid" &&
                                                                        <>
                                                                            {
                                                                                value.billingdata.clinicToPatientBilling &&
                                                                                <>
                                                                                    <button className="btn btn-sm me-2"
                                                                                        onClick={(e) => {
                                                                                            this.setState({
                                                                                                viewPaymentModal: true,
                                                                                                invoiceModalOperation: "view",
                                                                                                selectedAppointment: value,
                                                                                            });
                                                                                        }}><i className="bi bi-eye"></i></button>
                                                                                </>
                                                                            }
                                                                        </>
                                                                    }
                                                                </td>
                                                            </tr>
                                                        )
                                                    } else {
                                                        return null;
                                                    }
                                                } else {
                                                    return null;
                                                }
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    {
                        this.state.viewPaymentModal &&
                        <InvoiceModal viewPaymentModal={this.state.viewPaymentModal}
                            invoiceOperation={this.state.invoiceModalOperation}
                            selectedAppointment={this.state.selectedAppointment}
                            servicesArray={this.state.servicesType}
                            hideModal={(recallList: boolean) => {
                                this.setState({ viewPaymentModal: false, selectedAppointment: undefined, invoiceModalOperation: '' });
                                if (recallList) {
                                    const type = this.state.selectedProviderSelect === 'all' ? 'clinic' : 'provider';
                                    const uniqueId = type === 'clinic' ? this.state.selectedClinicSelect : this.state.selectedProviderSelect;
                                    const year = this.state.selectedYearMonthSelect.split("-")[0];
                                    const month = this.state.selectedYearMonthSelect.split("-")[1];
                                    if (year && month) {
                                        this.props.billintEntryAppointment({
                                            month: month,
                                            year: year,
                                            uniqueId: uniqueId,
                                            type: type,
                                            invoiceType: this.props.invoiceType,
                                            fullname: this.state.searchName
                                        });
                                    }
                                }
                            }}
                            {...this.props}></InvoiceModal>
                    }
                </div>
            </section>
        );
    }
    handlePaymentRequest(appointment: PaymentBillableAppointmentResponse) {
        console.log('appointment',appointment);
        
        if (appointment) {
            this.props.loadingStart();
            const obj = {
                "appointmentId": Number(appointment.uniqueid),
                "senderId": Number(getCookie().xpr_user_id),
                "sender": CHAT.SENDER.USER,
                "action": CHAT.ACTION.PRFC,
                "actionType": CHAT.ACTION_TYPE.PAYMENT_REQUEST,
                "text": appointment.clinic.name + ' requested ' + appointment.billingdata.clinicToPatientBilling.total + ' amount of payment from you.',
                "createdAt": firebase.firestore.FieldValue.serverTimestamp(),
                "updatedAt": firebase.firestore.FieldValue.serverTimestamp(),
                "sendToCId": appointment.clinic.uniqueid,
                "sendToDId": appointment.doctor.uniqueid,
                "dependantId": Number(appointment.dependant.uniqueid),
                "dateTime": {
                    "date": appointment.scheduledDate,
                    "time": appointment.scheduledTime
                },
                "firstName": this.props.userData.firstName,
                "lastName": this.props.userData.lastName,
            };
            console.log('firebaseObj',obj);
            
            this.db.collection(configs.CHAT_COLLECTION).doc().set(obj)
                .then(() => {
                    const type = this.state.selectedProviderSelect === 'all' ? 'clinic' : 'provider';
                    const uniqueId = type === 'clinic' ? this.state.selectedClinicSelect : this.state.selectedProviderSelect;
                    const year = this.state.selectedYearMonthSelect.split("-")[0];
                    const month = this.state.selectedYearMonthSelect.split("-")[1];
                    if (year && month) {
                        setTimeout(() => {
                            this.props.billintEntryAppointment({
                                month: month,
                                year: year,
                                uniqueId: uniqueId,
                                type: type,
                                invoiceType: this.props.invoiceType,
                                fullname: this.state.searchName
                            });
                            this.props.loadingEnd(false);
                        }, 3500);
                    } else {
                        this.props.loadingEnd(false);
                    }
                })
                .catch((error: any) => {
                    this.props.logout();
                    this.props.history.push('/');
                    this.props.loadingEnd(false);
                    console.error("Error writing document: ", error);
                });
        }
    }
}
const mapStateToProps = (state: IAppState) => ({
    paymentState: state.payment,
    userData: state.userData.userDataResponse
});
const mapDispatchToProps = (dispatch: any) => ({
    billableEntryFetch: (request: PaymentBillableEntryRequest) => {
        dispatch(paymentBillableEntryPending(request));
    },
    billintEntryAppointment: (request: PaymentBillableAppointmentRequest) => {
        dispatch(paymentBillableAppointmentPending(request));
    },
    loadingStart: () => {
        dispatch(fetchLoadingPending());
    },
    loadingEnd: (status: any) => {
        dispatch(fetchLoadingSuccess(status));
    },
    logout: () => {
        dispatch(userLogout());
    }
});
export default connect(mapStateToProps, mapDispatchToProps)(AllInvoice);