import { useState, useEffect } from "react";
import { confirmAlert } from "react-confirm-alert";
import Pagination from "react-js-pagination";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { IAppState } from "../../../store";
import {
  deletePatientList,
  getPatientLists,
  statusUpdateForPatientList,
} from "../../services/messageCenter";
import {
  fetchLoadingPending,
  fetchLoadingSuccess,
} from "../../../store/loadingIndicator/actions";

import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

export const CampaignPatientList = () => {
  const [page, setPage] = useState(1);
  const [results, setResults] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [patientListState, setPatientListState] = useState([]);
  const history = useHistory();
  const dispatch = useDispatch();

  const userData = useSelector(
    (state: IAppState) => state.userData.userDataResponse,
    shallowEqual
  );

  const handlePageChange = (page: any) => {
    setPage(page);
  };

  const onDeleteClick = (item: any) => {
    confirmAlert({
      title: "",
      message: "Are you sure you want to delete ?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            dispatch(fetchLoadingPending());
            deletePatientList({
              clinicid: userData.clinics[0].id,
              id: item.id,
            })
              .then((success: any) => {
                toast.error(success.message);
                getPatientList();
              })
              .catch((err) => {
                dispatch(fetchLoadingSuccess(false));
                toast.error("Something went wrong.");
              });
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  const onStatusChange = (item: any) => {
    console.log("item", item);
    confirmAlert({
      title: "",
      message: "Are you sure you want to update ?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            dispatch(fetchLoadingPending());
            statusUpdateForPatientList({
              clinicid: userData.clinics[0].id,
              id: item.id,
            })
              .then((success: any) => {
                toast.success(success.message);
                getPatientList();
              })
              .catch((err) => {
                dispatch(fetchLoadingSuccess(false));
                toast.error("Something went wrong.");
              });
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  const getPatientList = () => {
    if (userData && userData.clinics[0] && userData.clinics[0].id) {
      dispatch(fetchLoadingPending());
      getPatientLists({
        clinicid: userData.clinics[0].id,
        pageno: page,
        offset: results,
      })
        .then((success: any) => {
          dispatch(fetchLoadingSuccess(false));
          if (success.error === false) {
            setPatientListState(success.data);
            if (success.data[0]) {
              setTotalCount(success.data[0].total);
            }
            return;
          }
          toast.error(success.message);
        })
        .catch((err) => {
          dispatch(fetchLoadingSuccess(false));
          toast.error("Something went wrong.");
        });
    }
  };

  useEffect(() => {
    getPatientList();
  }, []);

  return (
    <section className="recall-recare-sec p-4 camp-popups-sec">
      <div className="row mx-0">
        <div className="col-12">
          {patientListState.length > 0 && (
            <div className="advances-table">
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th scope="col" className="w-25">Name</th>
                    <th scope="col">Total Patients</th>
                    <th scope="col">status</th>
                    <th scope="col" className="text-center w-20">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {patientListState.map((item: any, index: number) => {
                    return (
                      <tr key={index}>
                        <td>
                          <span className="d-block fw-medium">{item.name}</span>
                        </td>
                        <td>{item.patient_ids.split(",").length}</td>
                        <td>{item.isactive ? "Active" : "Inactive"}</td>
                        <td>
                          <div className="noti-btns d-flex align-self-center btn-link justify-content-center">
                            <div className="form-check form-switch d-inline-block align-self-center mx-2">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="flexSwitchCheckDefault"
                                checked={item.isactive}
                                onChange={() => {
                                  onStatusChange(item);
                                }}
                              />
                            </div>
                            <div
                              onClick={() => {
                                history.push(
                                  "/message-center/campaigns/patients",
                                  {
                                    id: item.id,
                                    name: item.name,
                                    patient_ids: item.patient_ids,
                                  }
                                );
                              }}
                            >
                              Edit
                            </div>
                            <button
                              className="btn p-0 btn-link text-secondary"
                              onClick={() => {
                                onDeleteClick(item);
                              }}
                            >
                              <i className="ms-2 bi bi-x-circle-fill text-danger"></i>
                            </button>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              <div className="d-flex justify-content-end">
                <Pagination
                  itemClass="page-item"
                  linkClass="page-link"
                  hideDisabled
                  activePage={page}
                  itemsCountPerPage={results}
                  totalItemsCount={Number(totalCount)}
                  onChange={handlePageChange}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};
