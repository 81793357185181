import React, { useEffect, useMemo, useState } from 'react';
import Pagination from 'react-js-pagination';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { CHAT } from '../../../constants';
import { IAppState } from '../../../store';
import { MyScheduleHeader } from '../../components/mySchedule/MyScheduleHeader';
import configs from '../../configs/apiConfigs';
import firebaseInstance from '../../helpers/firebase';
import { getAppointments, sendManualReview } from '../../services/appointment';
import moment from 'moment';
import { RequestForMedicalHistoryComp } from '../../components/mySchedule/RequestMedicalHistory';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { DigitalDoc } from '../../components/digitaldoc/DigitalDoc';
import { storeChatData } from '../../../store/chat/action';
import { PatientDetailModel } from '../../components/mySchedule/PatientDetailModel';
import CONSTANT_LABELS from '../../../constants/labels';
import { removeCountryCode } from '../../../utils/global';
import { confirmAlert } from 'react-confirm-alert';
import { fetchLoadingPending, fetchLoadingSuccess } from '../../../store/loadingIndicator/actions';
import { toast } from 'react-toastify';
import { NoteModal } from '../../../components/note/NoteModal';
import { getCookie } from '../../utils/cookies';
import firebase from 'firebase';
import InvoiceModal from '../../../components/payment/InvoiceModal';
let subscriptionToAppointmentChange: any = null;
export const Completed = () => {
    const [providerList, setProviderList] = useState([]);
    const [selectedProvider, setSelectedProvider] = useState('');
    const [page, setPage] = useState(1);
    const [results, setResults] = useState(30);
    const [totalCount, setTotalCount] = useState(0);
    const [appointmentItemList, setAppointmentItemList] = useState([]);
    const userData = useSelector((state: IAppState) => state.userData.userDataResponse, shallowEqual);
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [currentActiveModalAppointment, setCurrentActiveModalAppointment]: any = useState(null);
    const [isRefreshing, setIsRefreshing] = useState(false);
    const [digitalDocShow, setShowDigitalDocShow] = useState(false);
    const [showPatientDetailModal, setShowPatientDetailModal] = useState(false);
    const [name, setName] = useState('');
    const [date, setDate] = useState('');
    const [showNoteModal, setShowNoteModal] = useState(false);
    const serviceType = useSelector((state: IAppState) => state.payment.servicesTypeResponse?.serviceType);
    const paymentState = useSelector((state: IAppState) => state.payment);

    useEffect(() => {
        return () => {
            if (subscriptionToAppointmentChange) subscriptionToAppointmentChange();
        }
    }, []);
    useEffect(() => {
        if (userData && userData.clinics && userData.clinics[0] && userData.status && userData.status.error === false && userData.status.msg !== "") {
            let providers = userData.clinics[0].provider;
            providers = providers.filter((item: any) => item.onBoarding.status === 1 && item.enableAppointment === "1");
            setProviderList(providers);
            listenRealtimeAppointmentChange();
        }
    }, [userData]);
    useEffect(() => {
        fecthAppointmentListAsync();
    }, [selectedProvider, page, results, date]);
    useEffect(() => {
        if (name.length > 3) {
            fecthAppointmentListAsync();
        } else if (name === '') {
            fecthAppointmentListAsync();
        }
    }, [name]);
    const fecthAppointmentListAsync = async (realTime = false) => {
        try {
            let modelType = 'clinic';
            let Id = userData.clinics[0].uniqueId;
            if (selectedProvider !== '') {
                Id = selectedProvider;
                modelType = 'doctor';
            }
            const request = {
                appointmentType: 'completed',
                modelType: modelType,
                page: page,
                results: results,
                Id: Id
            };
            if (name !== '' && name.length > 3) {
                Object.assign(request, { fullname: name });
            }
            if (date !== '') {
                Object.assign(request, { searchdate: date });
            }
            if (realTime === true) {
                setIsRefreshing(true);
            } else {
                setIsLoading(true);
            }
            const response = await getAppointments(request);
            if (response && response.status && response.status.error === false) {
                setAppointmentItemList(response.appointments);
                if (page === 1) {
                    setTotalCount(response.total);
                }
            }
            if (realTime === true) {
                setIsRefreshing(false);
            } else {
                setIsLoading(false);
            }
        } catch (err) {
            if (realTime === true) {
                setIsRefreshing(false);
            } else {
                setIsLoading(false);
            }
            console.log(err);
        }
    }
    const handlePageChange = (page: any) => {
        setPage(page);
    }
    const listenRealtimeAppointmentChange = () => {
        subscriptionToAppointmentChange && subscriptionToAppointmentChange();
        subscriptionToAppointmentChange = firebaseInstance.db.collection(configs.CHAT_COLLECTION).where('actionType', 'in', [CHAT.ACTION_TYPE.STATUS])
            .where('sendToCId', '==', userData.clinics[0].uniqueId)
            .onSnapshot((snapshot: any) => {
                fecthAppointmentListAsync(true);
            });
    }
    const handleDigitalDocClick = (appointment: any) => {
        setCurrentActiveModalAppointment(appointment);
        setShowDigitalDocShow(true);
    }
    const handlePatientDetailAppointmentClick = (item: any) => {
        setShowPatientDetailModal(true);
        setCurrentActiveModalAppointment(item);
    }
    const addChatPin = (item: any) => {
        dispatch(storeChatData({
            ...item,
            fetched: true,
            pinType: "chat",
            openPin: true,
            appointmentId: item.uniqueid,
            sendToCId: item.clinic.uniqueid
        }));
    }
    const manualReview = async (appointment: any) => {
        try {
            confirmAlert({
                title: '',
                message: "This action will send review request to patient, please confirm.",
                buttons: [
                    {
                        label: 'Yes',
                        className: 'btn btn-outline-primary',
                        onClick: async () => {
                            try {
                                dispatch(fetchLoadingSuccess(true));
                                const response = await sendManualReview(appointment.uniqueid, userData.clinics[0].uniqueId,);
                                dispatch(fetchLoadingSuccess(false));
                                if (response && response.status && response.status.error === false) {
                                    fecthAppointmentListAsync(true);
                                    toast.success(response.status.msg);
                                } else {
                                    toast.error(response.status.msg);
                                    dispatch(fetchLoadingSuccess(false));
                                }
                            } catch (err) {
                                toast.error("Review sent failed");
                                dispatch(fetchLoadingSuccess(false));
                            }
                        }
                    },
                    {
                        label: 'No',
                        className: 'btn btn-outline-primary',
                        onClick: () => {

                        }
                    }
                ]
            });
        } catch (err: any) {
            dispatch(fetchLoadingSuccess(false));
            if (err.msg) {
                toast.error(err.msg);
            } else {
                toast.error('Mark appt done failed.');
            }
        }
    }
    const handleShowNote = (appointment: any) => {
        setCurrentActiveModalAppointment(appointment);
        setShowNoteModal(true);
    };
    const handlePaymentRequest = async (appointment: any) => {
        const obj = {
            "appointmentId": Number(appointment.uniqueid),
            "senderId": Number(getCookie().xpr_user_id),
            "sender": CHAT.SENDER.USER,
            "action": CHAT.ACTION.PRFC,
            "actionType": CHAT.ACTION_TYPE.PAYMENT_REQUEST,
            "text": appointment.clinic.name + ' requested ' + appointment.billingdata.clinicToPatientBilling.total + ' amount of payment from you.',
            "createdAt": firebase.firestore.FieldValue.serverTimestamp(),
            "updatedAt": firebase.firestore.FieldValue.serverTimestamp(),
            "sendToCId": appointment.clinic.uniqueid,
            "sendToDId": appointment.doctor.uniqueid,
            "dependantId": Number(appointment.dependant.uniqueid),
            "dateTime": {
                "date": appointment.date,
                "time": appointment.time
            },
            "firstName": userData.firstName,
            "lastName": userData.lastName,
        };
        try {
            dispatch(fetchLoadingPending());
            await firebaseInstance.setDocumentIntoCollection(configs.CHAT_COLLECTION, obj);
            toast.success('Payment request sent successfully.');
            dispatch(fetchLoadingSuccess(false));
        } catch (err) {
            console.log("err", err);
            dispatch(fetchLoadingSuccess(false));
            toast.error('Payment request sent failed.');
        }
    }
    return (
        <React.Fragment>
            <MyScheduleHeader onFetchAppointment={fecthAppointmentListAsync}></MyScheduleHeader>
            <section className="schedule-sec px-3">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th scope="col">
                                            <div>NAME</div>
                                            <div className="d-flex">
                                                <input className="form-control form-control-sm" placeholder="Search by name" value={name} onChange={(e) => {
                                                    setPage(1);
                                                    setName(e.target.value);
                                                }} />
                                                <button className="btn btn-sm btn-secondary ms-2" onClick={(e) => {
                                                    setPage(1);
                                                    setName('');
                                                }}><i className="bi bi-x"></i></button>
                                            </div>
                                        </th>
                                        <th scope="col">
                                            <div>Provider</div>
                                            {
                                                useMemo(() => {
                                                    return (
                                                        <select className="form-select form-select-sm" value={selectedProvider} onChange={(e) => {
                                                            setPage(1);
                                                            setSelectedProvider(e.target.value);
                                                        }}>
                                                            <option value=''>All provider</option>
                                                            {
                                                                providerList.map((value: any, index) => {
                                                                    return (
                                                                        <option value={value.uniqueid} key={value.uniqueid + "provider"}>{value.fullname}</option>
                                                                    )
                                                                })
                                                            }
                                                        </select>
                                                    )
                                                }, [providerList, selectedProvider])
                                            }
                                        </th>
                                        <th scope="col">
                                            <div>STATUS</div>
                                            <div className="d-flex">
                                                <input className="form-control form-control-sm" type="date" id="datePicker" name="dateSchedule" value={date} onChange={(e) => {
                                                    setPage(1);
                                                    setDate(e.target.value);
                                                }}></input>
                                                <button className="btn btn-sm btn-secondary ms-2" onClick={(e) => {
                                                    setPage(1);
                                                    setDate('');
                                                    fecthAppointmentListAsync(true);
                                                }}><i className="bi bi-x"></i></button>
                                            </div>
                                        </th>
                                        <th className="text-end" scope="col">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        isLoading && !isRefreshing &&
                                        <tr className="spinner-border" role="status"></tr>
                                    }
                                    {
                                        isRefreshing && !isLoading &&
                                        <tr className="spinner-border" role="status"></tr>
                                    }
                                    {
                                        isRefreshing && isLoading &&
                                        <tr className="spinner-border" role="status"></tr>
                                    }
                                    {
                                        useMemo(() => {
                                            if (!isLoading && appointmentItemList.length === 0) {
                                                return <tr>No records found</tr>
                                            }
                                            if (userData && userData.clinics[0] && !isLoading) {
                                                return (
                                                    <>
                                                        {
                                                            appointmentItemList.map((item: any) => {
                                                                return (
                                                                    <ItemRow item={item}
                                                                        key={item.uniqueid}
                                                                        handleDigitalDocClick={handleDigitalDocClick}
                                                                        addChatPin={addChatPin}
                                                                        userData={userData}
                                                                        handlePatientDetailAppointmentClick={handlePatientDetailAppointmentClick}
                                                                        manualReview={manualReview}
                                                                        handleShowNote={handleShowNote}
                                                                        handlePaymentRequest={handlePaymentRequest}
                                                                        serviceType={serviceType}
                                                                        paymentState={paymentState}
                                                                        fecthAppointmentListAsync={fecthAppointmentListAsync}
                                                                    ></ItemRow>
                                                                )
                                                            })
                                                        }
                                                    </>
                                                )
                                            }
                                        }, [appointmentItemList, isLoading])
                                    }
                                </tbody>
                            </table>
                            <div className="d-flex justify-content-end">
                                <Pagination
                                    itemClass="page-item"
                                    linkClass="page-link"
                                    hideDisabled
                                    activePage={page}
                                    itemsCountPerPage={results}
                                    totalItemsCount={Number(totalCount)}
                                    onChange={handlePageChange}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {
                useMemo(() => {
                    if (digitalDocShow && currentActiveModalAppointment) {
                        return (
                            <DigitalDoc
                                appointment={currentActiveModalAppointment}
                                hideModal={() => {
                                    setShowDigitalDocShow(false);
                                }}
                                fetchAppointment={fecthAppointmentListAsync}
                            ></DigitalDoc>
                        )
                    }
                }, [digitalDocShow, currentActiveModalAppointment])
            }
            {
                useMemo(() => {
                    if (showPatientDetailModal && currentActiveModalAppointment) {
                        return (
                            <PatientDetailModel
                                hideModal={() => {
                                    setShowPatientDetailModal(false);
                                }}
                                appointment={currentActiveModalAppointment}
                            >
                            </PatientDetailModel>
                        )
                    }
                }, [showPatientDetailModal, currentActiveModalAppointment])
            }
            {
                showNoteModal &&
                <NoteModal
                    data={currentActiveModalAppointment}
                    handleCancel={() => {
                        setShowNoteModal(false);
                    }} handleOk={() => {
                        setShowNoteModal(false);
                        toast.success("Note added successfully");
                    }}
                ></NoteModal>
            }
        </React.Fragment>
    );
};
const ItemRow = ({ item, handleDigitalDocClick, addChatPin, userData, handlePatientDetailAppointmentClick, manualReview,
    handleShowNote, handlePaymentRequest, paymentState, serviceType, fecthAppointmentListAsync }: any) => {
    const [operation, setOperation] = useState({
        viewPaymentModal: false,
        selectedAppointment: null,
        invoiceModalOperation: ""
    })
    return (
        <tr className={"table-row have-msg"} key={item.uniqueid}>
            <td>
                <span className="d-block fw-medium p-0 cursor-pointer btn-link" onClick={() => {
                    handlePatientDetailAppointmentClick(item)
                }}>
                    {
                        (item.dependant && item.dependant.fname && item.dependant.lname) ?
                            (item.dependant.fname + ' ' + item.dependant.lname).substring(0, 15) : ''
                    }
                </span>
                {/*   <button className="btn p-0 btn-link"
                    type="button"
                    onClick={(e) => { handlePatientDetailAppointmentClick(item) }}>
                    {
                        (item.dependant && item.dependant.fname && item.dependant.lname) ?
                            (item.dependant.fname + ' ' + item.dependant.lname).substring(0, 15) : ''
                    }
                </button> */}
                <span className="d-block text-muted">
                    {
                        item.dependant.phone && item.dependant.phone !== "" ? (CONSTANT_LABELS.PLUS_ONE + removeCountryCode(item.dependant.phone)) : item.dependant.email
                    }
                </span>
            </td>
            <td>
                <div className="d-flex p-0">
                    <div className="avatar flex-shrink-0 avatar-lg border rounded-circle me-3">
                        {
                            (item.doctor && item.doctor.photo && item.doctor.photo.name &&
                                item.doctor.photo.type) ?
                                <img src={configs.IMAGE_URL + 'doctor/' +
                                    item.doctor.photo.name + '-200x200.' + item.doctor.photo.type} alt="profile image" className="img-fluid" />
                                :
                                <>{item.doctor.fullname.split(" ")[0] ? item.doctor.fullname.split(" ")[0].charAt(0) : ''}
                                    {item.doctor.fullname.split(" ")[1] ? item.doctor.fullname.split(" ")[1].charAt(0) : ''}</>
                        }
                    </div>
                    <div>
                        <span className="d-block fw-medium"> {(item.doctor && item.doctor.fullname) ?
                            item.doctor.fullname : ''}</span>
                        <small className="d-block text-muted">{item.appointmentType ? item.appointmentType : ''}</small>
                    </div>
                </div>
            </td>
            <td>
                {
                    (item.metadata && item.metadata.appointment
                        && item.metadata.appointment.verifiedOn) &&
                    <>
                        <span className="d-block fw-medium p-0">Completed  (
                            {moment(
                                item.date + " " + item.time,
                                "YYYY-MM-DD LT"
                            ).format("MMM DD, YYYY - LT")}
                            )</span>
                        <small className="d-block p-0">Received on: {moment(item.metadata.appointment.verifiedOn, 'YYYY-MM-DD HH:mm:ss ZZ').format("MMM DD, YYYY")} - {moment(item.metadata.appointment.verifiedOn, 'YYYY-MM-DD HH:mm:ss ZZ').format("LT")}</small>
                    </>
                }
            </td>

            <td className="text-end">
                {
                    (item.patient && (item.patient.isSignInUser === true || item.dependant.phone) &&
                        !(item.billingdata && item.billingdata.clinicToPatientBilling &&
                            item.billingdata.clinicToPatientBilling.status === "paid")) &&

                    <OverlayTrigger
                        placement="auto"
                        overlay={
                            <Tooltip id="tooltip">
                                Quick chat
                            </Tooltip>
                        }
                    >
                        <button className="btn mx-1 p-1"
                            type="button"
                            onClick={(e) => { addChatPin(item); }}>
                            <i className="bi bi-chat-right-text"><span className="top-badges-yellow"></span></i></button>
                    </OverlayTrigger>
                }
                {
                    (item.patient && item.patient.isSignInUser === true && userData.accessEnabled?.digitaldoc === true) &&
                    <OverlayTrigger placement="auto" overlay={(props: any) => {
                        return (
                            <Tooltip id="button-tooltip" {...props}>Digital forms</Tooltip>
                        )
                    }}>
                        <button onClick={() => {
                            handleDigitalDocClick(item);
                        }} className="btn mx-1 p-1">
                            <i className="bi bi-file-medical"></i>
                        </button>
                    </OverlayTrigger>
                }
                {
                    moment(moment(item.date + " " + item.time, 'YYYY-MM-DD LT').add(item.duration, 'minutes').format('YYYY-MM-DD LT')).isBefore(moment().format('YYYY-MM-DD LT')) &&
                    <OverlayTrigger
                        placement="auto"
                        overlay={
                            <Tooltip id="tooltip">
                                Review
                            </Tooltip>
                        }
                    >
                        <button type="button" className="btn mx-1 p-1"
                            onClick={(e) => { manualReview(item) }}>
                            <i className="bi bi-hand-index-thumb"></i>
                        </button>
                    </OverlayTrigger>
                }
                {

                    <OverlayTrigger
                        placement="auto"
                        overlay={
                            <Tooltip id="tooltip">
                                Note
                            </Tooltip>
                        }
                    >
                        <button className="btn mx-1 p-1"
                            type="button"
                            onClick={() => {
                                handleShowNote(item);
                            }}>
                            <i className="bi bi-pencil-square"></i></button>
                    </OverlayTrigger>
                }
                {
                    (!item.billingdata.clinicToPatientBilling) && userData.clinics[0].payment_feature &&
                    <OverlayTrigger
                        overlay={
                            <Tooltip id="tooltip">
                                Create invoice
                            </Tooltip>
                        }
                    >
                        <button className="btn btn-sm me-1"
                            onClick={(e) => {
                                if (paymentState.fetchInitPaymentResponse) {
                                    const clinic = paymentState.fetchInitPaymentResponse.clinics.find((clinic: any) => { return clinic.uniqueId === item.clinic.uniqueid });
                                    if (clinic) {
                                        setOperation({
                                            viewPaymentModal: true,
                                            invoiceModalOperation: "create",
                                            selectedAppointment: {
                                                ...item,
                                                clinic: {
                                                    ...item.clinic,
                                                    metadata: clinic.metadata
                                                }
                                            },
                                        })
                                    }
                                }
                            }}
                        ><i className="bi bi-plus-square"></i>
                        </button>
                    </OverlayTrigger>
                }
                {
                    item.billingdata.clinicToPatientBilling &&
                    <>
                        {
                            userData.clinics[0].payment_feature &&
                            <>
                                <OverlayTrigger
                                    overlay={
                                        <Tooltip id="tooltip">
                                            View invoice
                                        </Tooltip>
                                    }
                                >
                                    <button className="btn btn-sm me-1"
                                        onClick={(e) => {
                                            if (paymentState.fetchInitPaymentResponse) {
                                                const clinic = paymentState.fetchInitPaymentResponse.clinics.find((clinic: any) => { return clinic.uniqueId === item.clinic.uniqueid });
                                                if (clinic) {
                                                    setOperation({
                                                        viewPaymentModal: true,
                                                        invoiceModalOperation: "view",
                                                        selectedAppointment: {
                                                            ...item,
                                                            clinic: {
                                                                ...item.clinic,
                                                                metadata: clinic.metadata
                                                            }
                                                        },
                                                    })
                                                }
                                            }
                                        }}><i className="bi bi-eye"></i></button>
                                </OverlayTrigger>
                                <OverlayTrigger
                                    overlay={
                                        <Tooltip id="tooltip">
                                            Edit invoice
                                        </Tooltip>
                                    }
                                >
                                    <button className={"btn btn-sm me-1"}
                                        onClick={(e) => {
                                            if (paymentState.fetchInitPaymentResponse) {
                                                const clinic = paymentState.fetchInitPaymentResponse.clinics.find((clinic: any) => { return clinic.uniqueId === item.clinic.uniqueid });
                                                if (clinic) {
                                                    setOperation({
                                                        viewPaymentModal: true,
                                                        invoiceModalOperation: "edit",
                                                        selectedAppointment: {
                                                            ...item,
                                                            clinic: {
                                                                ...item.clinic,
                                                                metadata: clinic.metadata
                                                            }
                                                        },
                                                    })
                                                }
                                            }
                                        }}><i className="bi bi-pencil"></i></button>
                                </OverlayTrigger>
                            </>
                        }
                        {
                            userData.clinics[0].payment_feature &&
                            <>
                                {
                                    !item.metadata.appointment.payment ?
                                        <OverlayTrigger
                                            overlay={
                                                <Tooltip id="tooltip">
                                                    Payment Request
                                                </Tooltip>
                                            }
                                        >
                                            <button className={"btn btn-sm me-1"} onClick={(e) => {
                                                handlePaymentRequest(item);
                                            }}><i className="bi bi-cursor"></i></button>
                                        </OverlayTrigger>
                                        :
                                        item.billingdata?.clinicToPatientBilling?.status !== "paid" ?
                                        <OverlayTrigger
                                            overlay={
                                                <Tooltip id="tooltip">
                                                    Resend Request
                                                </Tooltip>
                                            }
                                        >
                                            <button className={"btn btn-sm me-2 "} onClick={(e) => {
                                                handlePaymentRequest(item);
                                            }}><i className="bi bi-arrow-clockwise"></i></button>
                                        </OverlayTrigger> : null
                                }
                            </>
                        }
                    </>
                }
            </td>
            {
                operation?.viewPaymentModal &&
                <InvoiceModal viewPaymentModal={operation?.viewPaymentModal}
                    invoiceOperation={operation?.invoiceModalOperation}
                    selectedAppointment={operation?.selectedAppointment || undefined}
                    servicesArray={serviceType}
                    hideModal={(recallList: boolean) => {
                        if (recallList) {
                            fecthAppointmentListAsync();
                        }
                        setOperation({
                            viewPaymentModal: false,
                            selectedAppointment: null,
                            invoiceModalOperation: ""
                        });
                    }}
                ></InvoiceModal>
            }
        </tr>
    )
}