const CONSTANT_LABELS = {
    LABELS: {
        LOGIN: "Signin",
        EMAIL: "Email address:",
        PASSWORD: "Password:",
        FORGOT_PASSWORD: "Forget Password?",
        PRACTICES: "Practices",
        PLUS: "+",
        PROVIDERS: "Providers",
        NEW_PATIENT_REQUEST: "New Requests",
        THIS_MONTH: "This month",
        AVG_RATING: "Avg. Rating",
        GROWTH: "Growth",
        HISTORY: "History",
        BILLING: "Billing",
        MYBIILS: 'My Bills',
        MY_ACCOUNT: "My Account",
        LOGOUT: "Sign out",
        PATIENT_APPOINTMENT: "Patient Appointments",
        REQUEST: "Requests",
        SCHEDULED: "Scheduled",
        COMPLETED: "Completed",
        PATIENT: {
            NAME: "Name",
            DOCTOR: "Doctor",
            TYPE: "Type",
            STATUS: "Status",
            VALUE: "Value"
        },
        USER_INFO: "User info",
        FIRST_NAME: "First name",
        LAST_NAME: "Last name",
        VERIIED: "VERIFIED",
        UNVERIFIED: "UNVERIFIED",
        RESEND_EMAIL: "Resend email",
        CHANGE_PASSWORD: "Change password",
        CURRENT_PASSWORD: "Current password",
        NEW_PASSWORD: "New password",
        RETYPE_PASSWORD: "Retype password",
        ADMINISTRATOR: "Administrator",
        ADMINISTRATOR_TABLE: {
            NAME: 'Name',
            EMAIL: 'Email',
            ROLE: 'Role',
            NOTIFICATION: 'Email Notification'
        },
        PRACTICE_DESCRIPTION: 'Practice Description',
        PROFILE_DESCRIPTION: 'Profile Description',
        PRACTICE_PHOTOS: 'Practice Photos',
        PRACTICE_QUESTION: 'Practice Questions',
        PROFILE_QUESTION: 'Profile Questions',
        PRACTICE_FEATURE: 'Practice Features',
        PRACTICE_HYGIENE_FEATURE: 'Practice Hygiene Features',
        CONTACT_INFORMATION: 'Contact Information',
        GENERAL_INFORMATION: 'General Information',
        GENDER: 'Gender: ',
        LANGUAGE: 'Language: ',
        BOARD_CERTIFICATIONS: 'Board Certifications',
        EDUCATION: 'Education',
        GI_EDIT: {
            ADDRESS: 'Address :',
            PHONE: 'Mobile Number',
            EMAIL: 'Email :',
            WEBSITE: 'Website :'
        },
        PRACTICE_HOUR: 'Practice Hours',
        PH_TABLE: {
            DAY: 'Day',
            START: 'Start',
            END: 'End'
        },
        ASSIGNMENT: 'Assignment',
        A_INFO: {
            ACCEPT: 'Yes, we accept assignment',
            NO: 'No, we require patients to pay the full amount and be reimbursed from their insurance company directly'
        },
        PROVIDER: {
            PROVIDERS: 'Providers',
            HOURS: 'Hours',
            AVAILABILITY: 'Availability',
            APPOINTMENT: 'Appointment',
            SERVICES: 'Services',
            ACTION: 'Action',
            APPOINTMENT_REQUEST: 'Appointment request',
            COMPLETED: '% Completed',
            CLINICS: "Clinics",
            PROVIDERMAPPING: 'Patient Management System Mapping'
        },
        ADD_CLINIC: {
            PRACTICE: {
                ADD: 'Add New Practice',
                MSG: 'Please ensure that all of the following information is correct.',
                ADD_LOGO: 'Add Logo',
                NAME: 'Practice Name',
                NUMBER: 'Mobile Number',
                EMAIL: 'Email Address',
                WEBSITE: 'Website'
            }
        },
        PAYMENT: 'Payments',
        EMPLOYEE: 'Employee pre-screening',
        PMSAPPOINTMENT: 'PMS Appointments',
        CONTACTLESS_PAYMENETS: 'Contactless Payments'
    },
    BUTTON_LABEL: {
        LOGIN: "Sign in",
        SEND_LINK: "Reset password",
        CANCLE: "Cancel",
        SAVE: "Save"
    },
    LINK: {
        FORGOT_PASSWORD: "Forgot Password ?",
        LEGAL: "Legal",
        COPYRIGHT: "Copyright © " + new Date().getFullYear() + " XCARE PRO. All rights reserved.",
        PRIVACY: "Privacy",
        RETURN_TO_SIGNIN: "Back to signin",
        PROFILE: "Profile",
        PROVIDER_AVAILABILITY: "Providers Availablity",
        APPOINTMENT: "Appointments",
        SETTINGS: "Settings"
    },
    REQUIRED: {
        EMAIL: "This field is required.",
        PASSWORD: "This field is required.",
        EMAIL_REQUIRED: "Please enter email address.",
        LAST_NAME_REQUIRED: "Please enter last name.",
        FIRST_NAME_REQUIRED: "Please enter first name.",
        NAME_REQUIRED: 'Name is required.',
        NAME_TAKEN: 'Name already taken.',
        PHONE_REQUIRED: 'Mobile Number is Required',
        WEBSITE_REQUIRED: 'Website is Required',
        FULLNAME_REQUIRED: 'Full name is required.',
        LANGUAGES_REQUIRED: 'Languages is required.',
        SPECIALITY_REQUIRED: 'Speciality is required.',
        CLINIC_SELECT_ONE: 'Please select atleast one clinic.',
        ADDRESS_REQUIRED: 'Please enter address.',
        ALL_FIELD_REQUIRED: 'All fields are required.'
    },
    MESSAGE: {
        RESET_PASSWORD_LINK_SENT: "Reset password link has been sent on your email.",
        SUCCESS_API_MESSAGE: "Success"
    },
    PLUS_ONE: '+1'
}

export default CONSTANT_LABELS;